import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { constant, stubTrue, stubFalse } from 'lodash-es';
import { RadioButton } from '@shopline/dashboard-ui';
import {
  Message,
  MessageWrapper,
} from '@shopline/dashboard-ui/dist/esm/components/input';
import { Text, Dropdown } from 'components';
import {
  FieldRow,
  Field,
  Input,
  RadioButtonWrapper,
} from 'features/components';
import {
  LOADED_ANY_PAGE,
  LOADED_HOME_PAGE,
  ADDED_PRODUCT_TO_CART,
  LOADED_CHECKOUT_PAGE,
  PLACED_AN_ORDER,
  SIGNUP_AS_MEMBER,
} from 'constants/trackerEvents';
import PARENT_E2E_PREFIX from '../../e2ePrefix';
import { useIsCreate } from '../../useIsCreate';
import { TrackerEventSelector, useOptions } from '../TrackerEventSelector';
import { FormFooter } from './FormFooter';
import { useDefaultValues, useTrackerForm } from './useTrackerForm';
import { useCanSaveCurrentTracker } from './useCanSaveCurrentTracker';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-base-tracker-form`;

const stubNull = constant(null);

const defaultAvailableEvents = [
  LOADED_HOME_PAGE,
  ADDED_PRODUCT_TO_CART,
  LOADED_CHECKOUT_PAGE,
  PLACED_AN_ORDER,
  SIGNUP_AS_MEMBER,
];

const allEvents = [
  LOADED_ANY_PAGE,
  LOADED_HOME_PAGE,
  ADDED_PRODUCT_TO_CART,
  LOADED_CHECKOUT_PAGE,
  PLACED_AN_ORDER,
  SIGNUP_AS_MEMBER,
];

const SingleEventDropdown = (props) => {
  const dropdownOptions = useOptions({ options: allEvents });
  return (
    <Dropdown
      block
      width="100%"
      minHeight="2.25rem"
      useDefaultBorder
      disabledShadow
      options={dropdownOptions}
      {...props}
    />
  );
};

const emptyObject = {};
const emptyRules = [];

export const BaseTrackerForm = ({
  tracker,
  readonly,
  additionalDefaultValuesMap = emptyObject,
  additionalConfigDataMap = emptyObject,
  additionalRules = emptyRules,
  allowMultiEvents = true,
  allowOtherEvents = false,
  children = stubNull,
}) => {
  const { t } = useTranslation(['tracker', 'common']);
  const isCreate = useIsCreate();
  const defaultValues = useDefaultValues({
    tracker,
    additionalDefaultValuesMap,
  });
  const [form, meta] = useTrackerForm({
    defaultValues,
    additionalConfigDataMap,
    additionalRules,
  });
  const handleIsLoadedAnyPageOn = useCallback(() => {
    meta.handlers.isLoadedAnyPage.onFocus();
    meta.handlers.isLoadedAnyPage.onChange(stubTrue);
    meta.handlers.events.setError('');
  }, [meta.handlers.isLoadedAnyPage, meta.handlers.events]);
  const handleIsLoadedAnyPageOff = useCallback(() => {
    meta.handlers.isLoadedAnyPage.onFocus();
    meta.handlers.isLoadedAnyPage.onChange(stubFalse);
  }, [meta.handlers.isLoadedAnyPage]);

  const canSaveCurrentTracker = useCanSaveCurrentTracker({
    isShopline: form?.isShopline,
  });

  const handleEventsChange = useCallback(
    (value) => {
      meta.handlers.events.onFocus();
      meta.handlers.events.onChange(value);
      if (value.length > 0) {
        meta.handlers.events.setError('');
      }
    },
    [meta.handlers.events],
  );
  const renderCodeMessages = useMemo(
    () => [() => meta.errors.code],
    [meta.errors.code],
  );
  const eventsHasError = meta.errors.events !== '';

  return (
    <>
      <FieldRow>
        <Field title={t('field.trackerKind')}>
          <Text fontType="Body" color="INK_800">
            {t('displayKind', { context: form.kind })}
          </Text>
        </Field>
      </FieldRow>
      <FieldRow>
        <Field title={t('field.trackerEvents')}>
          {allowMultiEvents ? (
            <>
              <RadioButtonWrapper>
                <RadioButton
                  disabled={readonly}
                  checked={form.isLoadedAnyPage}
                  label={t('displayEvent', {
                    context: LOADED_ANY_PAGE,
                  })}
                  onChange={handleIsLoadedAnyPageOn}
                />
              </RadioButtonWrapper>
              <RadioButtonWrapper>
                <RadioButton
                  disabled={readonly || !allowOtherEvents}
                  checked={!form.isLoadedAnyPage}
                  label={t('displayEvent', {
                    context: 'others',
                  })}
                  onChange={handleIsLoadedAnyPageOff}
                />
                <TrackerEventSelector
                  value={form.events}
                  errorMessage={meta.errors.events}
                  onFocus={meta.handlers.events.onFocus}
                  onChange={handleEventsChange}
                  placeholder={t('placeholder.trackerEvents')}
                  options={defaultAvailableEvents}
                  disabled={readonly || form.isLoadedAnyPage}
                />
              </RadioButtonWrapper>
              <MessageWrapper>
                <Message status={eventsHasError ? 'invalid' : 'valid'}>
                  {eventsHasError
                    ? meta.errors.events
                    : t('label.events', {
                        context: allowOtherEvents ? '' : 'disabled',
                      })}
                </Message>
              </MessageWrapper>
            </>
          ) : (
            <SingleEventDropdown
              value={form.events}
              onFocus={meta.handlers.events.onFocus}
              onChange={handleEventsChange}
            />
          )}
        </Field>
      </FieldRow>
      <FieldRow>
        <Field title={t('field.code', { context: form.kind })}>
          <Input
            disabled={readonly}
            block
            value={form.code}
            status={meta.errors.code !== '' ? 'invalid' : 'valid'}
            renderMessages={renderCodeMessages}
            e2eInputId={`${E2E_PREFIX}-code-input`}
            e2eMessagesId={`${E2E_PREFIX}-code-errors`}
            placeholder={t('placeholder.code', { context: form.kind })}
            {...meta.handlers.code}
          />
        </Field>
      </FieldRow>
      {children({ form, meta })}
      <FormFooter
        canSave={canSaveCurrentTracker}
        meta={meta}
        isCreate={isCreate}
        readonly={readonly}
      />
    </>
  );
};
