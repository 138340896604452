import { useCallback } from 'react';
import { useToast } from 'hooks/useToast';
import { usePermissions } from 'hooks/usePermissions';
import { TIKTOK, META } from 'constants/trackerKinds';
import { useTrackerInstalled } from './useTrackerKindInstalled';

export const useBlockCreate = ({ kind }) => {
  const { createUIToast: createInstalledToast } = useToast('installedToast');
  const { createUIToast: createCannotCreateToast } =
    useToast('cannotCreateToast');
  const installed = useTrackerInstalled({ kind });
  const canCreate = usePermissions('eventTrackers', 'create');
  const shouldRedirect = [TIKTOK, META].includes(kind);
  const blockCreate = useCallback(() => {
    if (!canCreate && !shouldRedirect) {
      createCannotCreateToast({
        id: `cannot-create-${kind}`,
        type: 'warning',
        duration: 5000,
      });
    } else if (installed) {
      createInstalledToast({
        id: `installed-${kind}`,
        type: 'information',
        duration: 5000,
        description: true,
      });
    }
  }, [
    canCreate,
    installed,
    createCannotCreateToast,
    createInstalledToast,
    shouldRedirect,
    kind,
  ]);
  const shouldBlockCreate = (!canCreate && !shouldRedirect) || installed;
  return [shouldBlockCreate, blockCreate];
};
