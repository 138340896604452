import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup, Button } from '@shopline/dashboard-ui';
import { Loading } from 'components';
import PostMessageContext from 'contexts/PostMessage';
import * as kinds from './kinds';
import { StateContext, RedirectPopupContext } from './context';

const getPath = ({ kind, postMessageState }) => {
  switch (kind) {
    case kinds.FACEBOOK_ENTRANCE:
      return postMessageState['requestFacebookEntrancePath'];
    case kinds.TT4B:
      return postMessageState['requestTiktokPath'];
    default:
      return '';
  }
};

const requestPath = ({ kind, postMessageActions }) => {
  switch (kind) {
    case kinds.FACEBOOK_ENTRANCE:
      postMessageActions.requestFacebookEntrancePath();
      break;
    case kinds.TT4B:
      postMessageActions.requestTiktokPath();
      break;
    default:
      break;
  }
};

const composeKindNs = (kind) => `${kind}Popup`;

const nsList = [...Object.values(kinds).map(composeKindNs), 'commonPopup'];

export const RedirectPopup = () => {
  const { kind, behavior, open } = useContext(StateContext);
  const { close } = useContext(RedirectPopupContext);
  const { t } = useTranslation(nsList);
  const ns = composeKindNs(kind);
  const [postMessageState, postMessageActions] = useContext(PostMessageContext);
  const path = getPath({
    kind,
    postMessageState,
  });
  const isPathLoaded = path !== '';
  const redirect = useCallback(() => {
    if (isPathLoaded) {
      postMessageActions.redirectToInnerPath(path);
    }
  }, [isPathLoaded, path, postMessageActions]);
  useEffect(() => {
    if (open && !isPathLoaded) {
      requestPath({ kind, postMessageActions });
    }
  }, [open, isPathLoaded, kind, postMessageActions]);
  if (!open) {
    return null;
  }
  const tOptions = { ns, context: behavior };
  return (
    <Popup
      title={t('title', tOptions)}
      onClose={close}
      renderFooter={() => (
        <>
          <Button.Default onClick={close} height="LARGE">
            {t('cancel', tOptions)}
          </Button.Default>
          <Button.Primary
            onClick={redirect}
            height="LARGE"
            width="WIDE"
            disabled={!isPathLoaded}
          >
            {t('ok', tOptions)}
          </Button.Primary>
        </>
      )}
    >
      {isPathLoaded ? t('content', tOptions) : <Loading />}
    </Popup>
  );
};
