import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import getOr from 'lodash/fp/getOr';
import prop from 'lodash/fp/prop';
import { FieldRow, Field, Input } from 'features/components';
import { YAHOO } from 'constants/trackerKinds';
import PARENT_E2E_PREFIX from '../../e2ePrefix';
import { BaseTrackerForm } from './BaseTrackerForm';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-g-ads-tracker-form`;

export const additionalDefaultValuesMap = {
  code2: getOr('', ['configData', 'trackingCode2']),
};

export const additionalConfigDataMap = {
  trackingCode2: prop('code2'),
};

const CODE_PATTERN = /^[0-9a-zA-Z-_]{0,50}$/;

const useRules = () => {
  const { t } = useTranslation(['tracker', 'fieldValidation']);
  return useMemo(
    () => ({
      code2: {
        required: {
          value: true,
          message: t('required', {
            context: `${YAHOO}Code`,
            ns: 'fieldValidation',
            fieldName: t('ID'),
          }),
        },
        maxLength: {
          value: 50,
          message: t('maxLength', {
            context: `${YAHOO}Code`,
            ns: 'fieldValidation',
            fieldName: t('ID'),
            value: 50,
          }),
        },
        pattern: {
          value: CODE_PATTERN,
          message: t('fieldValidation.code.pattern', {
            context: `${YAHOO}Code`,
          }),
        },
      },
    }),
    [t],
  );
};

export const YahooTrackerForm = (props) => {
  const { t } = useTranslation('tracker');
  const rules = useRules();
  return (
    <BaseTrackerForm
      {...props}
      allowOtherEvents
      additionalDefaultValuesMap={additionalDefaultValuesMap}
      additionalConfigDataMap={additionalConfigDataMap}
      additionalRules={rules}
    >
      {({ form, meta }) => (
        <FieldRow>
          <Field title={t(`field.${YAHOO}.code2`)}>
            <Input
              disabled={props.readonly}
              block
              value={form.code2}
              status={meta.errors.code2 !== '' ? 'invalid' : 'valid'}
              renderMessages={[() => meta.errors.code2]}
              e2eInputId={`${E2E_PREFIX}-code2-input`}
              e2eMessagesId={`${E2E_PREFIX}-code2-errors`}
              placeholder={t(`placeholder.${YAHOO}.code2`)}
              {...meta.handlers.code2}
            />
          </Field>
        </FieldRow>
      )}
    </BaseTrackerForm>
  );
};
