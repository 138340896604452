import { createStructuredSelector } from 'reselect';
import { cond, constant, iteratee, stubTrue } from 'lodash-es';
import prop from 'lodash/fp/prop';
import { LOADED_ANY_PAGE } from 'constants/trackerEvents';

const eventTypesSelector = cond([
  [iteratee({ isLoadedAnyPage: true }), constant([LOADED_ANY_PAGE])],
  [stubTrue, prop('events')],
]);

export const createTrackerDataSelector = (selectorMap = {}) =>
  createStructuredSelector({
    id: prop('id'),
    kind: prop('kind'),
    token: prop('token'),
    tracker: createStructuredSelector({
      eventTypes: eventTypesSelector,
      trackingHtml: prop('html'),
      configData: createStructuredSelector({
        trackingCode: prop('code'),
        ...selectorMap,
      }),
    }),
  });
