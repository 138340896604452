import { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { negate, identity, stubFalse } from 'lodash-es';
import getOr from 'lodash/fp/getOr';
import prop from 'lodash/fp/prop';
import { Checkbox, TextButton, Icons } from '@shopline/dashboard-ui';
import { FieldRow, Field, Input, Toggle } from 'features/components';
import { G_ADS } from 'constants/trackerKinds';
import { LOADED_ANY_PAGE } from 'constants/trackerEvents';
import { ENHANCED_CONVERSIONS } from 'constants/featureKeys';
import { useStaff } from 'hooks/useStaff';
import { useFeatureControl } from 'hooks/useFeaturesControl';
import PARENT_E2E_PREFIX from '../../e2ePrefix';
import { BaseTrackerForm } from './BaseTrackerForm';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-g-ads-tracker-form`;

export const LinkButton = ({ children, ...props }) => (
  <TextButton.Primary as="a" target="_blank" {...props}>
    {children}
    <Icons.Solid.Connect size="MEDIUM" color="PRIMARY_600" />
  </TextButton.Primary>
);

export const additionalDefaultValuesMap = {
  isShopline: getOr(false, ['configData', 'isShopline']),
  isLoadedAnyPage: stubFalse,
  events: getOr([LOADED_ANY_PAGE], 'eventTypes'),
  code2: getOr('', ['configData', 'trackingCode2']),
  enhancedConversions: getOr(false, [
    'configData',
    'enableEnhancedConversions',
  ]),
};

export const additionalConfigDataMap = {
  isShopline: prop('isShopline'),
  trackingCode2: prop('code2'),
  enableEnhancedConversions: prop('enhancedConversions'),
};

const CODE_PATTERN = /^[0-9a-zA-Z-_]{0,50}$/;

const useRules = () => {
  const { t } = useTranslation(['tracker', 'fieldValidation']);
  return useMemo(
    () => ({
      code2: {
        required: {
          value: true,
          message: t('required', {
            context: `${G_ADS}Code`,
            ns: 'fieldValidation',
            fieldName: t('ID'),
          }),
        },
        maxLength: {
          value: 50,
          message: t('maxLength', {
            context: `${G_ADS}Code`,
            ns: 'fieldValidation',
            fieldName: t('ID'),
            value: 50,
          }),
        },
        pattern: {
          value: CODE_PATTERN,
          message: t('fieldValidation.code.pattern', {
            context: `${G_ADS}Code`,
          }),
        },
      },
    }),
    [t],
  );
};

export const GAdsTrackerForm = (props) => {
  const { t } = useTranslation('tracker');
  const isShoplineStaff = useStaff([
    'isSuperAdmin',
    'isMarketer',
    'isInspector',
  ]);
  const canEditIsShoplineField = useStaff(['isSuperAdmin', 'isMarketer']);
  const enhancedConversionsEnabled = useFeatureControl(ENHANCED_CONVERSIONS);

  const rules = useRules();

  const popoverProps = useMemo(
    () => ({
      direction: 'BOTTOM_LEFT',
      title: t('tipsPopover.title'),
      content: (
        <Trans
          i18nKey={`placeholder.${G_ADS}.enhancedConversions`}
          t={t}
          components={{
            br: <br />,
            lk: (
              <LinkButton
                href={t('main:docLink', { context: 'gAdsEnhanced' })}
              />
            ),
          }}
        />
      ),
    }),
    [t],
  );

  return (
    <BaseTrackerForm
      {...props}
      allowMultiEvents={false}
      additionalDefaultValuesMap={additionalDefaultValuesMap}
      additionalConfigDataMap={additionalConfigDataMap}
      additionalRules={rules}
    >
      {({ form, meta }) => (
        <>
          <FieldRow>
            <Field title={t(`field.${G_ADS}.code2`)}>
              <Input
                disabled={props.readonly}
                block
                value={form.code2}
                status={meta.errors.code2 !== '' ? 'invalid' : 'valid'}
                renderMessages={[() => meta.errors.code2]}
                e2eInputId={`${E2E_PREFIX}-code2-input`}
                e2eMessagesId={`${E2E_PREFIX}-code2-errors`}
                placeholder={t(`placeholder.${G_ADS}.code2`)}
                {...meta.handlers.code2}
              />
            </Field>
          </FieldRow>
          {enhancedConversionsEnabled && (
            <FieldRow>
              <Field
                title={t(`field.${G_ADS}.enhancedConversions`)}
                popoverProps={popoverProps}
              >
                <Toggle
                  disabled={props.readonly}
                  label={t(`label.${G_ADS}.enhancedConversions`)}
                  value={form.enhancedConversions}
                  onClick={() => {
                    meta.handlers.enhancedConversions.onFocus();
                    meta.handlers.enhancedConversions.onChange(
                      negate(identity),
                    );
                  }}
                />
              </Field>
            </FieldRow>
          )}
          {isShoplineStaff && (
            <FieldRow>
              <Field title={t(`field.${G_ADS}.isShopline`)}>
                <Checkbox.Default
                  disabled={props.readonly || !canEditIsShoplineField}
                  label={t(`label.${G_ADS}.isShopline`)}
                  checked={form.isShopline}
                  onChange={() => {
                    meta.handlers.isShopline.onFocus();
                    meta.handlers.isShopline.onChange(negate(identity));
                  }}
                />
              </Field>
            </FieldRow>
          )}
        </>
      )}
    </BaseTrackerForm>
  );
};
