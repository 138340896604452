import { useCallback } from 'react';
import { META, TIKTOK } from 'constants/trackerKinds';
import {
  useRedirectPopupContext,
  redirectKinds,
  redirectBehaviors,
} from '../RedirectPopup';
import { EventTrackerKind } from './EventTrackerKind';
import { useTrackerInstalled } from './useTrackerKindInstalled';
import { useBlockCreate } from './useBlockCreate';

const redirectKindMap = {
  [META]: redirectKinds.FACEBOOK_ENTRANCE,
  [TIKTOK]: redirectKinds.TT4B,
};

export const RedirectEventTrackerKind = ({ kind }) => {
  const installed = useTrackerInstalled({ kind });
  const { open } = useRedirectPopupContext();
  const [shouldBlockCreate, blockCreate] = useBlockCreate({ kind });
  const handleClick = useCallback(() => {
    if (shouldBlockCreate) {
      blockCreate();
      return;
    }
    const redirectKind = redirectKindMap[kind];
    if (redirectKind) {
      open({ kind: redirectKind, behavior: redirectBehaviors.CREATE });
    }
  }, [open, kind, shouldBlockCreate, blockCreate]);
  return (
    <EventTrackerKind kind={kind} installed={installed} onClick={handleClick} />
  );
};
