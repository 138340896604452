import { OPEN, CLOSE } from './actionTypes';

export const initialState = {
  kind: '', // facebookEntrance | tt4b
  behavior: '', // 'create' | 'edit' | 'remove'
  open: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case OPEN:
      return {
        kind: action.payload.kind,
        behavior: action.payload.behavior,
        open: true,
      };
    case CLOSE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
