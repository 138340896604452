import { OPEN, CLOSE } from './actionTypes';
import * as kinds from './kinds';

export const open = ({ kind, behavior }) => {
  if (!Object.values(kinds).includes(kind)) {
    throw new Error(`invalid kind: ${kind}`);
  }
  return {
    type: OPEN,
    payload: { kind, behavior },
  };
};

export const close = () => ({
  type: CLOSE,
});
