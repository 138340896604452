import { useCallback, useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { constant, stubTrue } from 'lodash-es';
import prop from 'lodash/fp/prop';
import { Button, Icons, RadioButton, TextButton } from '@shopline/dashboard-ui';
import { LINE_POINTS } from 'constants/trackerKinds';
import * as trackerConversions from 'constants/trackerConversions';
import PostMessageContext from 'contexts/PostMessage';
import { Text } from 'components';
import {
  FieldRow,
  Field,
  Input,
  RadioButtonWrapper,
} from 'features/components';
import PARENT_E2E_PREFIX from '../../e2ePrefix';
import { useIsCreate } from '../../useIsCreate';
import {
  FormFooter,
  Container as FormFooterContainer,
  CancelButton,
} from './FormFooter';
import { useDefaultValues, useTrackerForm } from './useTrackerForm';
import { useCanSaveCurrentTracker } from './useCanSaveCurrentTracker';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-base-tracker-form`;

export const additionalDefaultValuesMap = {
  isShopline: stubTrue,
  missions: constant([trackerConversions.PLACED_AN_ORDER]),
};

export const additionalConfigDataMap = {
  isShopline: prop('isShopline'),
  conversions: prop('missions'),
};

export const availableMissions = [trackerConversions.PLACED_AN_ORDER];

const additionalRules = [];

export const LinePointsTrackerForm = ({ tracker, readonly }) => {
  const { t } = useTranslation(['tracker', 'common']);
  const isCreate = useIsCreate();
  const { merchantId } = useParams();
  const defaultValues = useDefaultValues({
    tracker,
    additionalDefaultValuesMap,
  });
  const [form, meta] = useTrackerForm({
    defaultValues,
    additionalConfigDataMap,
    additionalRules,
  });

  const canSaveCurrentTracker = useCanSaveCurrentTracker({
    isShopline: form.isShopline,
  });

  const renderCodeMessages = useMemo(
    () => [() => meta.errors.code],
    [meta.errors.code],
  );
  const [, { execIntercom }] = useContext(PostMessageContext);
  const handleExecIntercomShowNewMessage = useCallback(() => {
    execIntercom({ command: 'showNewMessage' });
  }, [execIntercom]);

  const showIntercomHint = isCreate && !canSaveCurrentTracker;

  return (
    <>
      <FieldRow>
        <Field title={t('field.trackerKind')}>
          <Text fontType="Body" color="INK_800">
            {t('displayKind', { context: form.kind })}
          </Text>
        </Field>
      </FieldRow>
      <FieldRow>
        <Field title={t(`field.${LINE_POINTS}.missions`)}>
          {availableMissions.map((mission) => (
            <RadioButtonWrapper>
              <RadioButton
                disabled={readonly}
                checked={form.missions.includes(mission)}
                label={t(`label.${LINE_POINTS}.missions`, {
                  context: mission,
                })}
              />
            </RadioButtonWrapper>
          ))}
        </Field>
      </FieldRow>
      <FieldRow>
        {showIntercomHint ? (
          <Field
            title={
              <Trans
                i18nKey={`field.${LINE_POINTS}.intercom`}
                t={t}
                components={{
                  intercom: (
                    <TextButton.Primary
                      onClick={handleExecIntercomShowNewMessage}
                    />
                  ),
                }}
              />
            }
          />
        ) : (
          <Field title={t('field.code', { context: form.kind })}>
            <Input
              disabled={readonly}
              block
              value={form.code}
              status={meta.errors.code !== '' ? 'invalid' : 'valid'}
              renderMessages={renderCodeMessages}
              e2eInputId={`${E2E_PREFIX}-code-input`}
              e2eMessagesId={`${E2E_PREFIX}-code-errors`}
              placeholder={t('placeholder.code', { context: form.kind })}
              {...meta.handlers.code}
            />
          </Field>
        )}
      </FieldRow>
      {showIntercomHint ? (
        <FormFooterContainer>
          <Button.Primary
            e2eId={`${E2E_PREFIX}-intercom-btn`}
            onClick={handleExecIntercomShowNewMessage}
            Icon={Icons.Outline.Chat}
          >
            {t('Contact', { ns: 'common' })}
          </Button.Primary>
          <CancelButton
            e2eId={`${E2E_PREFIX}-cancel-btn`}
            to={{ pathname: `/${merchantId}/trackers` }}
          >
            {t('Cancel', {
              ns: 'common',
              context: 'return',
            })}
          </CancelButton>
        </FormFooterContainer>
      ) : (
        <FormFooter
          canSave={canSaveCurrentTracker}
          meta={meta}
          isCreate={isCreate}
          readonly={readonly}
        />
      )}
    </>
  );
};
