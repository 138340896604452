import * as trackerKinds from 'constants/trackerKinds';
import * as trackerEvents from 'constants/trackerEvents';
import * as trackerConversions from 'constants/trackerConversions';

export const tracker = {
  ID: 'ID Code',
  [`ID_${trackerKinds.CUSTOM}`]: "Customized Tracker's Name",
  displayKind: 'Unknown Tracker',
  [`displayKind_${trackerKinds.BING}`]: 'Bing Conversion Pixel',
  [`displayKind_${trackerKinds.CRITEO}`]: 'Criteo',
  [`displayKind_${trackerKinds.GA}`]: 'Google Analytics',
  [`displayKind_${trackerKinds.GA4}`]: 'Google Analytics 4',
  [`displayKind_${trackerKinds.G_ADS}`]: 'Google Ads',
  [`displayKind_${trackerKinds.G_REMARKETING}`]: 'Google Remarketing Tag',
  [`displayKind_${trackerKinds.GTM}`]: 'Google Tag Manager',
  [`displayKind_${trackerKinds.LINE}`]: 'LINE Tag (LAP)',
  [`displayKind_${trackerKinds.LINE_POINTS}`]: 'LINE POINTS Tag (CPA)',
  [`displayKind_${trackerKinds.META}`]: 'Meta Pixel (New)',
  [`displayKind_${trackerKinds.YAHOO}`]: 'Yahoo! Native Ad Conversion Pixel',
  [`displayKind_${trackerKinds.TIKTOK}`]: 'TikTok Pixel',
  [`displayKind_${trackerKinds.CUSTOM}`]: 'Customization Tracker',
  [`displayKind_${trackerKinds.LEGACY_FB_PIXEL}`]:
    'Facebook Conversion Pixel (Old)',
  [`displayKind_${trackerKinds.LEGACY_FB_AUDIENCE}`]:
    'Facebook Custom Audience Pixel (Old)',
  displayEvent: 'Unknown Event',
  displayEvent_others: 'Add multiple events',
  [`displayEvent_${trackerEvents.LOADED_ANY_PAGE}`]:
    'A customer has loaded any page in my store.',
  [`displayEvent_${trackerEvents.LOADED_HOME_PAGE}`]:
    'A customer has loaded my store homepage.',
  [`displayEvent_${trackerEvents.ADDED_PRODUCT_TO_CART}`]:
    'A customer has added product to cart.',
  [`displayEvent_${trackerEvents.LOADED_CHECKOUT_PAGE}`]:
    'A customer has loaded the checkout page.',
  [`displayEvent_${trackerEvents.PLACED_AN_ORDER}`]:
    'A customer has placed an order.',
  [`displayEvent_${trackerEvents.SIGNUP_AS_MEMBER}`]:
    'A customer has signed up as a member.',
  html: "Customized Event Tracker's HTML Code",
  field: {
    trackerKind: 'The tracking service I would like to implement is:',
    trackerEvents: 'I would like to track when:',
    code: 'Unknown Code',
    [`code_${trackerKinds.BING}`]: 'My Bing ID is:',
    [`code_${trackerKinds.CRITEO}`]: 'My Criteo ID is:',
    [`code_${trackerKinds.GA}`]: 'My Google Analytics Tracking ID is:',
    [`code_${trackerKinds.GA4}`]: 'My Google Analytics 4 Tracking ID is:',
    [`code_${trackerKinds.G_ADS}`]: 'My Google Ads conversion ID is:',
    [`code_${trackerKinds.G_REMARKETING}`]: 'My Google Remarketing Tag ID is:',
    [`code_${trackerKinds.GTM}`]: 'My Google Tag Manager ID is:',
    [`code_${trackerKinds.LINE}`]: 'My LINE Ads Tag Conversion ID is:',
    [`code_${trackerKinds.LINE_POINTS}`]:
      'My LINE POINTS Tag Conversion ID is:',
    [`code_${trackerKinds.YAHOO}`]: 'My Yahoo! Project ID is:',
    [`code_${trackerKinds.META}`]: 'My Meta Pixel ID is:',
    [`code_${trackerKinds.CUSTOM}`]: "My Customized Event Tracker's Name is:",
    [trackerKinds.G_ADS]: {
      isShopline: 'Shopline Ownership Setting',
      code2: 'My Google Ads conversion label is:',
      enhancedConversions:
        'Enable Google Conversion Tracking to support the "Enhanced Conversions" feature',
    },
    [trackerKinds.GA]: {
      enableEcommerce: 'Google Analytics - Enhanced E-commerce',
    },
    [trackerKinds.GA4]: {
      enhancedEC: 'Google Analytics 4 - Enhanced measurement events',
    },
    [trackerKinds.G_REMARKETING]: {
      isShopline: 'Shopline Ownership Setting',
      dynamic: 'Google Dynamic Remarketing',
      useUniqueId: 'Use system unique product IDs or product SKUs?',
      singleVariation: 'Show single variation or all variations?',
    },
    [trackerKinds.META]: {
      useUniqueId: 'Use system unique product IDs or product SKUs?',
      singleVariation: 'Show single variation or all variations?',
    },
    [trackerKinds.YAHOO]: {
      code2: 'My Yahoo! Pixel ID is:',
    },
    [trackerKinds.CUSTOM]: {
      html: 'My $t(html) is:',
    },
    [trackerKinds.LINE_POINTS]: {
      missions: 'I would like to track:',
      intercom:
        'If you wish to execute the LINE POINTS advertisement, please <intercom>contact the SHOPLINE Online Merchant Success Team</intercom> directly, and we will have a professional to assist you.',
    },
  },
  label: {
    events:
      'Recommended page to install this tracker: A customer has loaded any page in my store',
    events_disabled:
      'This tracking service is designed to be loaded in every page of your store.',
    [trackerKinds.G_ADS]: {
      isShopline: 'Set as Read-Only',
      enhancedConversions:
        'Enabling Google Conversion Tracking to support "Enhanced Conversions"',
    },
    [trackerKinds.GA]: {
      enableEcommerce: 'Enable Enhanced E-commerce',
    },
    [trackerKinds.GA4]: {
      enhancedEC: 'Go to GA4 Admin to enable',
    },
    [trackerKinds.G_REMARKETING]: {
      isShopline: 'Set as Read-Only',
      dynamic: 'Enable Dynamic Remarketing',
      useUniqueId: '',
      useUniqueId_productId: 'Use system unique product IDs',
      useUniqueId_sku: 'Use product SKUs',
      singleVariation: '',
      singleVariation_showAll: 'Show all variations',
      singleVariation_showOne: 'Show single variation',
    },
    [trackerKinds.META]: {
      useUniqueId: '',
      useUniqueId_productId: 'Use system unique product IDs',
      useUniqueId_sku: 'Use product SKUs',
      singleVariation: '',
      singleVariation_showAll: 'Show all variations',
      singleVariation_showOne: 'Show single variation',
    },
    [trackerKinds.LINE_POINTS]: {
      missions: '',
      [`missions_${trackerConversions.PLACED_AN_ORDER}`]: 'Action of purchase',
    },
  },
  placeholder: {
    trackerEvents: 'Please Select',
    code: '',
    [`code_${trackerKinds.BING}`]: 'eg. 1234567',
    [`code_${trackerKinds.CRITEO}`]: 'eg. 123456',
    [`code_${trackerKinds.GA}`]: 'eg. UA-000000-01',
    [`code_${trackerKinds.GA4}`]: 'eg. G-XXXXXXX',
    [`code_${trackerKinds.G_ADS}`]: 'eg. 888888888',
    [`code_${trackerKinds.G_REMARKETING}`]: 'eg. 1234567890',
    [`code_${trackerKinds.GTM}`]: 'eg. GTM-XXXX',
    [`code_${trackerKinds.LINE}`]: 'eg. 888888888',
    [`code_${trackerKinds.LINE_POINTS}`]: 'eg. FREECOINS_00000',
    [`code_${trackerKinds.YAHOO}`]: 'eg. 12345',
    [`code_${trackerKinds.META}`]: 'eg. 1234567890',
    [trackerKinds.GA4]: {
      enhancedEC:
        'Enhanced measurement Events allows you to enable options (events) in the Google Analytics 4 interface to measure user interactions with your content. After enabling, it can stream relevant events of website data, including browsing events, page vertical scrolling events, external domain click events, site search events, website embedded video participation events, and file download events',
    },
    [trackerKinds.G_ADS]: {
      code2: 'eg. aBC_dOe1fghIj2k3lmN',
      enhancedConversions:
        'We recommend that you turn on Enhanced Conversions to improve the accuracy of Google Ads conversion evaluations. We help you transmit enhanced conversions data from the server to Google using a secure channel, including more website behavior or events data, which can more deeply measure the effectiveness of Google Ads advertising and make ads delivery more accurate. In addition, it is recommended that you go to "Online Store Design > Design > Advanced Settings" to enable the cookie authorization prompt function to protect your website data transmission. It\'s also very important that you consult with your legal team to ensure compliance with privacy regulations before you choose a third-party service.<br /><lk>Learn more</lk>',
    },
    [trackerKinds.YAHOO]: {
      code2: 'eg. 123456',
    },
  },
  fieldValidation: {
    code: {
      pattern:
        'ID Code is invalid, Enter only half-formed English characters, numbers, - & _',
    },
    events: {
      custom: 'Please select at least one event',
    },
  },
  link: {
    [trackerKinds.GA4]: {
      enhancedEC: 'https://support.google.com/analytics/answer/9216061?hl=en',
    },
  },
  tipsPopover: {
    title: 'Hint',
    content:
      'Learn about frequently asked questions:<list><ga>Google Analytics Setup</ga><gaECommerce>Google Analytics Ecommerce Setup</gaECommerce></list>',
    links: {
      ga: `$t(main:docLink, {"context": "${trackerKinds.GA}"})`,
      gaECommerce: '$t(main:docLink, {"context": "gaECommerce"})',
    },
  },
};

export default tracker;
