import { useParams } from 'react-router-dom';
import { EventTrackerKind } from './EventTrackerKind';
import { useTrackerInstalled } from './useTrackerKindInstalled';
import { useBlockCreate } from './useBlockCreate';

export const GeneralEventTrackerKind = ({ kind }) => {
  const { merchantId } = useParams();
  const installed = useTrackerInstalled({ kind });
  const [shouldBlockCreate, blockCreate] = useBlockCreate({ kind });
  return (
    <EventTrackerKind
      kind={kind}
      installed={installed}
      to={
        shouldBlockCreate
          ? null
          : {
              pathname: `/${merchantId}/trackers/create`,
              search: `?kind=${kind}`,
            }
      }
      onClick={shouldBlockCreate ? blockCreate : null}
    />
  );
};
