import styled from 'styled-components';
import Text from 'components/Text';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const PageTitle = ({ children, hint = null }) => (
  <Container>
    <Text as="h2" fontType="DisplayLarge" color="INK_DEFAULT">
      {children}
    </Text>
    {hint}
  </Container>
);

export default PageTitle;
