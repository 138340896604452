import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { Checkbox } from '@shopline/dashboard-ui';

export const DestructivePopupCheckbox = ({
  children,
  values,
  onToggle,
  onMount,
}) => {
  const key = Array.isArray(children) ? children[0] : children;
  const toggle = useCallback(() => {
    onToggle(key);
  }, [onToggle, key]);
  useEffect(() => {
    onMount(key);
    return () => onMount(key, false);
  }, [onMount, key]);
  return (
    <Checkbox.Default
      checked={!!values[key]}
      onChange={toggle}
      label={children}
    />
  );
};
DestructivePopupCheckbox.propTypes = {
  children: PropTypes.oneOfType(
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ).isRequired,
  values: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
};
