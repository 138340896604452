import { useState, useCallback, useMemo, useEffect } from 'react';
import update from 'immutability-helper';
import { curryRight, mapValues, transform, merge } from 'lodash-es';
import eq from 'lodash/fp/eq';
import validate from 'utils/validate';

const curriedUpdate = curryRight(update, 2);

const getFieldError = (field, value, rules) => {
  const fieldRule = rules[field];
  if (!fieldRule) {
    return '';
  }
  return validate(value, fieldRule);
};

export const useSimpleForm = ({
  defaultValues,
  rules,
  validateDefaultValues,
  onSubmit,
  validateForm,
}) => {
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState(mapValues(defaultValues, () => ''));
  const [everFulfilled, setEverFulfilled] = useState(
    mapValues(defaultValues, () => false),
  );
  const [touched, setTouched] = useState(false);
  const handlers = useMemo(
    () =>
      mapValues(defaultValues, (_, field) => ({
        onChange: (value) => {
          setValues(
            curriedUpdate({
              [field]:
                typeof value === 'function'
                  ? {
                      $apply: value,
                    }
                  : {
                      $set: value,
                    },
            }),
          );
          const error = getFieldError(field, value, rules);
          const firstFulfilled = error === '' && !everFulfilled[field];
          if (firstFulfilled) {
            setEverFulfilled(
              curriedUpdate({
                [field]: {
                  $set: true,
                },
              }),
            );
          }
          if (everFulfilled[field] || firstFulfilled) {
            setErrors(
              curriedUpdate({
                [field]: {
                  $set: error,
                },
              }),
            );
          }
        },
        onFocus: () => {
          setTouched(true);
        },
        onBlur: ({ target: { value } }) => {
          const error = getFieldError(field, value, rules);
          setErrors(
            curriedUpdate({
              [field]: {
                $set: error,
              },
            }),
          );
        },
        setError: (error) => {
          setErrors(
            curriedUpdate({
              [field]: {
                $set: error,
              },
            }),
          );
        },
      })),
    [rules, defaultValues, everFulfilled],
  );
  const { hasErrors } = transform(
    errors,
    (result, value, key) => {
      if (!result.hasErrors) {
        result.hasErrors = value !== '';
      }
    },
    { hasErrors: false },
  );

  const handleSubmit = useCallback(async () => {
    const formErrors =
      typeof validateForm === 'function' ? await validateForm(values) : {};
    const errors = merge(
      mapValues(values, (value, field) => getFieldError(field, value, rules)),
      formErrors,
    );
    if (!Object.values(errors).every(eq(''))) {
      setErrors(errors);
      return;
    }
    await onSubmit(values);
  }, [validateForm, onSubmit, values, rules]);

  useEffect(() => {
    setTouched(false);
    if (validateDefaultValues) {
      setErrors(
        mapValues(defaultValues, (value, field) =>
          getFieldError(field, value, rules),
        ),
      );
    } else {
      setErrors(mapValues(defaultValues, () => ''));
    }
  }, [validateDefaultValues, defaultValues, rules]);

  return [
    values,
    { errors, handlers, setValues, handleSubmit, hasErrors, touched },
  ];
};

export default useSimpleForm;
