import * as trackerKinds from 'constants/trackerKinds';
import * as trackerEvents from 'constants/trackerEvents';
import * as trackerConversions from 'constants/trackerConversions';

export const tracker = {
  ID: 'ID',
  [`ID_${trackerKinds.CUSTOM}`]: '自订追踪工具名称',
  displayKind: 'Unknown Tracker',
  [`displayKind_${trackerKinds.BING}`]: 'Bing 关键字广告追踪码',
  [`displayKind_${trackerKinds.CRITEO}`]: 'Criteo',
  [`displayKind_${trackerKinds.GA}`]: 'Google Analytics',
  [`displayKind_${trackerKinds.GA4}`]: 'Google Analytics 4',
  [`displayKind_${trackerKinds.G_ADS}`]: 'Google Ads',
  [`displayKind_${trackerKinds.G_REMARKETING}`]: 'Google 再营销',
  [`displayKind_${trackerKinds.GTM}`]: 'Google 代码管理工具',
  [`displayKind_${trackerKinds.LINE}`]: 'LINE Tag (LAP)',
  [`displayKind_${trackerKinds.LINE_POINTS}`]: 'LINE POINTS 广告追踪码（CPA）',
  [`displayKind_${trackerKinds.META}`]: 'Meta 像素（新）',
  [`displayKind_${trackerKinds.YAHOO}`]: 'Yahoo! 原生广告追踪码',
  [`displayKind_${trackerKinds.TIKTOK}`]: 'TikTok Pixel',
  [`displayKind_${trackerKinds.CUSTOM}`]: '自订',
  [`displayKind_${trackerKinds.LEGACY_FB_PIXEL}`]:
    'Facebook 广告转换像素（旧）',
  [`displayKind_${trackerKinds.LEGACY_FB_AUDIENCE}`]:
    'Facebook 自订广告受众像素（旧）',
  displayEvent: 'Unknown Event',
  displayEvent_others: '新增其他事件',
  [`displayEvent_${trackerEvents.LOADED_ANY_PAGE}`]: '顾客造访网店内的任何网页',
  [`displayEvent_${trackerEvents.LOADED_HOME_PAGE}`]: '顾客造访网店首页',
  [`displayEvent_${trackerEvents.ADDED_PRODUCT_TO_CART}`]:
    '顾客将商品加入购物车',
  [`displayEvent_${trackerEvents.LOADED_CHECKOUT_PAGE}`]: '顾客造访结帐页面',
  [`displayEvent_${trackerEvents.PLACED_AN_ORDER}`]: '顾客完成下单',
  [`displayEvent_${trackerEvents.SIGNUP_AS_MEMBER}`]: '顾客注册为会员',
  html: '自订事件追踪 HTML 码',
  field: {
    trackerKind: '我想使用的追踪工具是：',
    trackerEvents: '我想追踪的事件是：',
    code: 'Unknown Code',
    [`code_${trackerKinds.BING}`]: '我的 Bing 广告转换像素追踪编号是：',
    [`code_${trackerKinds.CRITEO}`]: '我的 Criteo ID 是：',
    [`code_${trackerKinds.GA}`]: '我的 Google Analytics 追踪编号是：',
    [`code_${trackerKinds.GA4}`]: '我的 Google Analytics 4 追踪编号是：',
    [`code_${trackerKinds.G_ADS}`]: '我的 Google Ads 转换编号是：',
    [`code_${trackerKinds.G_REMARKETING}`]: '我的 Google 再营销追踪编号是：',
    [`code_${trackerKinds.GTM}`]: '我的 Google 代码管理工具追踪编号是：',
    [`code_${trackerKinds.LINE}`]: '我的 LINE Ads Tag 转换编号是：',
    [`code_${trackerKinds.LINE_POINTS}`]: '我的 LINE POINTS Tag 转换编号是：',
    [`code_${trackerKinds.YAHOO}`]: '我的 Yahoo! 广告项目编号是：',
    [`code_${trackerKinds.META}`]: '我的 Meta 像素 ID 是：',
    [`code_${trackerKinds.CUSTOM}`]: '我的自订事件追踪工具名称是：',
    [trackerKinds.G_ADS]: {
      isShopline: 'Shopline Ownership Setting',
      code2: '我的 Google Ads 转换标签是：',
      enhancedConversions: '是否启用 Google 转换追踪支援「强化转换」功能',
    },
    [trackerKinds.GA]: {
      enableEcommerce: 'Google Analytics - 电子商务',
    },
    [trackerKinds.GA4]: {
      enhancedEC: 'Google Analytics 4 - 加强型评估电子商务事件',
    },
    [trackerKinds.G_REMARKETING]: {
      isShopline: 'Shopline Ownership Setting',
      dynamic: 'Google 动态再营销广告',
      useUniqueId: '使用系统独立产品编号或商品货号？',
      singleVariation: '显示单个商品规格或所有商品规格？',
    },
    [trackerKinds.META]: {
      useUniqueId: '使用系统独立产品编号或商品货号？',
      singleVariation: '显示单个商品规格或所有商品规格？',
    },
    [trackerKinds.YAHOO]: {
      code2: '我的 Yahoo! 广告追踪编号是：',
    },
    [trackerKinds.CUSTOM]: {
      html: '我的$t(html)是：',
    },
    [trackerKinds.LINE_POINTS]: {
      missions: '我想追踪的活动是：',
      intercom:
        '若您希望执行 LINE POINTS 广告，请直接<intercom>联系 SHOPLINE 线上顾问</intercom>，我们会派专人为您服务。',
    },
  },
  label: {
    events: '建议设定追踪事件为：顾客造访网店内的任何网页',
    events_disabled: '此追踪工具已自动协助您埋设/追踪每个网页的相对应事件',
    [trackerKinds.G_ADS]: {
      isShopline: 'Set as Read-Only',
      enhancedConversions: '启用 Google 转换追踪支援「强化转换」',
    },
    [trackerKinds.GA]: {
      enableEcommerce: '启用电子商务',
    },
    [trackerKinds.GA4]: {
      enhancedEC: '前往 GA4 后台启用',
    },
    [trackerKinds.G_REMARKETING]: {
      isShopline: 'Set as Read-Only',
      dynamic: '启用动态再营销广告',
      useUniqueId: '',
      useUniqueId_productId: '使用系统独立产品编号',
      useUniqueId_sku: '使用商品货号',
      singleVariation: '',
      singleVariation_showAll: '显示所有商品规格',
      singleVariation_showOne: '显示单个商品规格',
    },
    [trackerKinds.META]: {
      useUniqueId: '',
      useUniqueId_productId: '使用系统独立产品编号',
      useUniqueId_sku: '使用商品货号',
      singleVariation: '',
      singleVariation_showAll: '显示所有商品规格',
      singleVariation_showOne: '显示单个商品规格',
    },
    [trackerKinds.LINE_POINTS]: {
      missions: '',
      [`missions_${trackerConversions.PLACED_AN_ORDER}`]: '导购型任务',
    },
  },
  placeholder: {
    trackerEvents: '请选择',
    code: '',
    [`code_${trackerKinds.BING}`]: '如：1234567',
    [`code_${trackerKinds.CRITEO}`]: '如：123456',
    [`code_${trackerKinds.GA}`]: '如：UA-000000-01',
    [`code_${trackerKinds.GA4}`]: '如：G-XXXXXXX',
    [`code_${trackerKinds.G_ADS}`]: '如：888888888',
    [`code_${trackerKinds.G_REMARKETING}`]: '如：1234567890',
    [`code_${trackerKinds.GTM}`]: '如：GTM-XXXX',
    [`code_${trackerKinds.LINE}`]: '如：888888888',
    [`code_${trackerKinds.LINE_POINTS}`]: '如：FREECOINS_00000',
    [`code_${trackerKinds.YAHOO}`]: '如：12345',
    [`code_${trackerKinds.META}`]: '如：1234567890',
    [trackerKinds.GA4]: {
      enhancedEC:
        '加强型评估可让您在 Google Analytics 4（分析）介面中启用选项（事件），评估使用者与您内容的互动情况。启用后能将网站资料串流相关事件，包含浏览事件、网页垂直卷动事件、外连网域点击事件、站内搜寻事件、网站内嵌影片参与事件、档案下载事件',
    },
    [trackerKinds.G_ADS]: {
      code2: '如：aBC_dOe1fghIj2k3lmN',
      enhancedConversions:
        '我们建议您开启「强化转换功能」，以利改善 Google Ads 转换评估准确度。我们协助您利用安全管道将强化转换资料由伺服器传送给 Google 端，包括更多网站行为或事件资料，能更深入衡量 Google Ads 广告成效，让投放更精准。此外，建议您至「网店设计 > 设计 > 进阶设计设定」启用 Cookies 授权提示功能，以利保护您的网站数据资料传送。同时非常重要的是，在您选择第三方服务之前，请咨询您的法律团队以确保遵守隐私法规。<br /><lk>了解更多</lk>',
    },
    [trackerKinds.YAHOO]: {
      code2: '如：123456',
    },
  },
  fieldValidation: {
    code: {
      pattern: '限输入半形英文字、数字、- 及 _',
    },
    events: {
      custom: '请至少选择一项事件',
    },
  },
  link: {
    [trackerKinds.GA4]: {
      enhancedEC:
        'https://support.google.com/analytics/answer/9216061?hl=zh-Hans',
    },
  },
  tipsPopover: {
    title: '提示',
    content:
      '了解常见问题：<list><ga>Google Analytics（数据分析）设定方式</ga><gaECommerce>启动 Google Analytics 的电子商务设定</gaECommerce></list>',
    links: {
      ga: `$t(main:docLink, {"context": "${trackerKinds.GA}"})`,
      gaECommerce: '$t(main:docLink, {"context": "gaECommerce"})',
    },
  },
};
