import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { Main } from './Main';
import { TrackerEditor } from './TrackerEditor';
import ToastList from './ToastList';
import AppBase from './AppBase';
import NotFound from './NotFound';

const FeatureRoute = () => (
  <Router>
    <Switch>
      <Route path="/:merchantId">
        <AppBase>
          <Switch>
            <Route
              exact
              path="/:merchantId"
              render={(props) => (
                <Redirect to={`/${props.match.params.merchantId}/trackers`} />
              )}
            />
            <Route exact path="/:merchantId/trackers" component={Main} />
            <Route
              path="/:merchantId/trackers/create"
              component={TrackerEditor}
            />
            <Route
              path="/:merchantId/trackers/:trackerId"
              component={TrackerEditor}
            />
            <Route
              path="/:merchantId/trackers/:trackerId/edit"
              component={TrackerEditor}
            />
            <Route component={NotFound} />
          </Switch>
        </AppBase>
      </Route>
      <Route component={NotFound} />
    </Switch>
    <Route component={ToastList} />
  </Router>
);

export default FeatureRoute;
