import { useTranslation } from 'react-i18next';
import { negate, identity } from 'lodash-es';
import getOr from 'lodash/fp/getOr';
import prop from 'lodash/fp/prop';
import { FieldRow, Field, Toggle } from 'features/components';
import { GA } from 'constants/trackerKinds';
import { BaseTrackerForm } from './BaseTrackerForm';

export const additionalDefaultValuesMap = {
  enableEcommerce: getOr(false, ['configData', 'enableEcommerce']),
};

export const additionalConfigDataMap = {
  enableEcommerce: prop('enableEcommerce'),
};

export const GaTrackerForm = (props) => {
  const { t } = useTranslation('tracker');
  return (
    <BaseTrackerForm
      {...props}
      additionalDefaultValuesMap={additionalDefaultValuesMap}
      additionalConfigDataMap={additionalConfigDataMap}
    >
      {({ form, meta }) => (
        <FieldRow>
          <Field title={t(`field.${GA}.enableEcommerce`)}>
            <Toggle
              disabled={props.readonly}
              label={t(`label.${GA}.enableEcommerce`)}
              value={form.enableEcommerce}
              onClick={() => {
                meta.handlers.enableEcommerce.onFocus();
                meta.handlers.enableEcommerce.onChange(negate(identity));
              }}
            />
          </Field>
        </FieldRow>
      )}
    </BaseTrackerForm>
  );
};
