import { createRef, useEffect } from 'react';
import { useToast } from 'hooks/useToast';
import { usePermissions } from 'hooks/usePermissions';

const hasShow = createRef(false);

export const useCannotCreateWarning = () => {
  const { createUIToast: createCannotCreateToast } =
    useToast('cannotCreateToast');
  const canCreate = usePermissions('eventTrackers', 'create');

  useEffect(() => {
    if (canCreate) {
      return;
    }
    if (hasShow.current) {
      return;
    }
    hasShow.current = true;
    createCannotCreateToast({
      type: 'warning',
      title: {
        params: {
          context: 'auto',
        },
      },
    });
  }, [createCannotCreateToast, canCreate]);
};
