import cond from 'lodash/cond';
import iteratee from 'lodash/iteratee';
import stubTrue from 'lodash/stubTrue';
import constant from 'lodash/constant';
import identity from 'lodash/identity';
import prop from 'lodash/fp/prop';
import {
  EXEC_INTERCOM,
  LANGUAGE_CHANGE,
  REDIRECT_TO_INNER_PATH,
  REQUEST_ADMIN_HOST,
  REQUEST_EVENT_TRACKERS_PATH,
  REQUEST_FACEBOOK_ENTRANCE_PATH,
  REQUEST_TIKTOK_PATH,
  ROUTE_CHANGE,
} from 'constants/iframeMessageType';

const parseType = (type, parse) =>
  cond([
    [iteratee({ type }), (data) => ({ $set: parse(data) })],
    [stubTrue, constant({ $apply: identity })],
  ]);

const requestPathConfigGenerator = (type) => ({
  initialValue: '',
  dispatch: () => ({ type }),
  parse: parseType(type, prop(['payload', 'path'])),
});

const config = {
  execIntercom: {
    initialValue: '',
    dispatch: (payload) => ({
      type: EXEC_INTERCOM,
      payload,
    }),
  },
  languageChange: {
    initialValue: null,
    parse: parseType(LANGUAGE_CHANGE, prop(['payload', 'lang'])),
  },
  redirectToInnerPath: {
    initialValue: null,
    dispatch: (path) => ({ type: REDIRECT_TO_INNER_PATH, payload: { path } }),
  },
  requestAdminHost: {
    initialValue: '',
    dispatch: () => ({ type: REQUEST_ADMIN_HOST }),
    parse: parseType(REQUEST_ADMIN_HOST, prop(['payload', 'host'])),
  },
  requestEventTrackersPath: requestPathConfigGenerator(
    REQUEST_EVENT_TRACKERS_PATH,
  ),
  requestFacebookEntrancePath: requestPathConfigGenerator(
    REQUEST_FACEBOOK_ENTRANCE_PATH,
  ),
  requestTiktokPath: requestPathConfigGenerator(REQUEST_TIKTOK_PATH),
  routeChange: {
    initialValue: '',
    dispatch: ({ path, search }) => ({
      type: ROUTE_CHANGE,
      payload: { path, search },
    }),
  },
};

export default config;
