import { useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { merge } from 'lodash-es';
import { useSimpleForm } from 'hooks/useSimpleForm';
import { useToast } from 'hooks/useToast';
import { useCreateTracker, usePatchTracker } from 'hooks/useTrackers';
import { useIsCreate } from '../../useIsCreate';
import { createDefaultValuesSelector } from './createDefaultValuesSelectors';
import { createTrackerDataSelector } from './createTrackerDataSelector';

export const useDefaultValues = ({ tracker, additionalDefaultValuesMap }) => {
  return useMemo(() => {
    const selector = createDefaultValuesSelector(additionalDefaultValuesMap);
    return selector(tracker);
  }, [additionalDefaultValuesMap, tracker]);
};

const emptyMap = {};
const emptyRules = [];

const CODE_PATTERN = /^[0-9a-zA-Z-_]{0,50}$/;

const useRules = ({ kind, rules }) => {
  const { t } = useTranslation(['tracker', 'fieldValidation']);
  return useMemo(() => {
    return merge(
      {
        code: {
          required: {
            value: true,
            message: t('required', {
              context: kind,
              ns: 'fieldValidation',
              fieldName: t('ID', { context: kind }),
            }),
          },
          maxLength: {
            value: 50,
            message: t('maxLength', {
              context: kind,
              ns: 'fieldValidation',
              fieldName: t('ID', { context: kind }),
              value: 50,
            }),
          },
          pattern: {
            value: CODE_PATTERN,
            message: t('fieldValidation.code.pattern', { context: kind }),
          },
        },
      },
      rules,
    );
  }, [t, kind, rules]);
};

export const useTrackerForm = ({
  defaultValues,
  additionalConfigDataMap = emptyMap,
  additionalRules = emptyRules,
} = {}) => {
  const { t } = useTranslation(['tracker']);
  const isCreate = useIsCreate();
  const history = useHistory();
  const { merchantId } = useParams();
  const { createUIToast: createCreateToast } = useToast('createSuccessToast');
  const { createUIToast: createPatchToast } = useToast('patchSuccessToast');
  const handleCreateSuccess = useCallback(() => {
    createCreateToast({
      type: 'success',
      duration: 5000,
      title: {
        params: {
          context: defaultValues.kind,
        },
      },
    });
  }, [createCreateToast, defaultValues]);
  const handlePatchSuccess = useCallback(() => {
    createPatchToast({
      type: 'success',
      duration: 5000,
      title: {
        params: {
          context: defaultValues.kind,
        },
      },
    });
  }, [createPatchToast, defaultValues]);
  const { mutateAsync: createTracker, isLoading: isCreating } =
    useCreateTracker({
      onSuccess: handleCreateSuccess,
    });
  const { mutateAsync: patchTracker, isLoading: isPatching } = usePatchTracker({
    onSuccess: handlePatchSuccess,
  });
  const handleSubmit = useCallback(
    async (values) => {
      const handler = isCreate ? createTracker : patchTracker;
      const trackerDataSelector = createTrackerDataSelector(
        additionalConfigDataMap,
      );
      await handler(trackerDataSelector(values));
      history.push(`/${merchantId}/trackers`);
    },
    [
      isCreate,
      createTracker,
      patchTracker,
      history,
      merchantId,
      additionalConfigDataMap,
    ],
  );
  const rules = useRules({ kind: defaultValues.kind, rules: additionalRules });
  const validateForm = useCallback(
    (form) => {
      if (!form.isLoadedAnyPage && form.events.length === 0) {
        return {
          events: t('fieldValidation.events.custom', {
            context: defaultValues.kind,
          }),
        };
      }
      return {};
    },
    [t, defaultValues.kind],
  );
  const [values, meta] = useSimpleForm({
    defaultValues,
    rules,
    validateDefaultValues: !isCreate,
    onSubmit: handleSubmit,
    validateForm,
  });
  return [
    values,
    { ...meta, isSubmitting: isCreate ? isCreating : isPatching },
  ];
};
