import styled from 'styled-components';
import { RadioButtonWrapper as Container } from '@shopline/dashboard-ui/dist/esm/components/choice/RadioButton';

export const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 0.25rem;
  }

  & > ${Container} {
    flex: 0 0 auto;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
`;
