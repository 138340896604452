import styled from 'styled-components';
import { Section } from '../../components';
import { TableWrapper } from '@shopline/dashboard-ui/dist/esm/components/table';
import { StyledTh } from '@shopline/dashboard-ui/dist/esm/components/table/TableHead';
import { StyledTd } from '@shopline/dashboard-ui/dist/esm/components/table/TableBody';

export const TableSection = styled(Section)`
  & > ${TableWrapper} {
    margin: -1.5rem;
    width: calc(100% + 3rem);
  }
  & ${StyledTh} {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  & ${StyledTh}:first-child, & ${StyledTd}:first-child {
    padding-left: 1.5rem;
  }
  & ${StyledTh}:last-child, & ${StyledTd}:last-child {
    padding-right: 1.5rem;
  }
`;
