import { useEffect, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';
import PostMessageContext from 'contexts/PostMessage';
import { usePermissions } from 'hooks/usePermissions';
import {
  Grid,
  Page,
  PageDescription,
  PageTitle,
  Section,
  TipsContentList,
  TipsContentLinkItem,
  // SectionCaption,
} from '../components';
import {
  RedirectPopup,
  RedirectPopupProvider,
  TableSection,
} from './components';
import PARENT_E2E_PREFIX from './e2ePrefix';
import { useBreadcrumb } from './useBreadcrumb';
import { useCannotCreateWarning } from './useCannotCreateWarning';
import EventTrackerList from './EventTrackerKindList';
import { EventTrackerTable } from './EventTrackerTable';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-index`;

export const Main = () => {
  const { t } = useTranslation(['main', 'pageTitle']);
  const [, { routeChange }] = useContext(PostMessageContext);
  const breadcrumbs = useBreadcrumb({ e2ePrefix: E2E_PREFIX });
  const canShow = usePermissions('eventTrackers', 'show');

  useCannotCreateWarning();

  useScrollToTopOnMount();
  useEffect(() => {
    routeChange({
      path: '',
      search: '',
    });
  }, [routeChange]);

  return (
    <RedirectPopupProvider>
      <Page
        titleLeftSection={
          <PageTitle>{t('Tracker Settings', { ns: 'pageTitle' })}</PageTitle>
        }
        breadcrumbs={breadcrumbs}
      >
        <PageDescription>{t('App Description')}</PageDescription>
        {canShow && (
          <Grid.Row>
            <Grid.Column disableGutter lg={12} md={12} sd={12}>
              <Section
                title={t('Add Tracker')}
                popoverProps={{
                  title: t('tipsPopover.title'),
                  content: (
                    <Trans
                      t={t}
                      i18nKey="tipsPopover.content"
                      components={{
                        list: <TipsContentList />,
                        ga: (
                          <TipsContentLinkItem
                            linkProps={{ href: t('tipsPopover.links.ga') }}
                          />
                        ),
                        gaECommerce: (
                          <TipsContentLinkItem
                            linkProps={{
                              href: t('tipsPopover.links.gaECommerce'),
                            }}
                          />
                        ),
                        gtm: (
                          <TipsContentLinkItem
                            linkProps={{
                              href: t('tipsPopover.links.gtm'),
                            }}
                          />
                        ),
                        gAds: (
                          <TipsContentLinkItem
                            linkProps={{
                              href: t('tipsPopover.links.gAds'),
                            }}
                          />
                        ),
                        gRemarketing: (
                          <TipsContentLinkItem
                            linkProps={{
                              href: t('tipsPopover.links.gRemarketing'),
                            }}
                          />
                        ),
                        bing: (
                          <TipsContentLinkItem
                            linkProps={{
                              href: t('tipsPopover.links.bing'),
                            }}
                          />
                        ),
                        yahoo: (
                          <TipsContentLinkItem
                            linkProps={{
                              href: t('tipsPopover.links.yahoo'),
                            }}
                          />
                        ),
                        metaPixel: (
                          <TipsContentLinkItem
                            linkProps={{
                              href: t('tipsPopover.links.metaPixel'),
                            }}
                          />
                        ),
                        line: (
                          <TipsContentLinkItem
                            linkProps={{
                              href: t('tipsPopover.links.line'),
                            }}
                          />
                        ),
                        linePoints: (
                          <TipsContentLinkItem
                            linkProps={{
                              href: t('tipsPopover.links.linePoints'),
                            }}
                          />
                        ),
                        ga4: (
                          <TipsContentLinkItem
                            linkProps={{
                              href: t('tipsPopover.links.ga4'),
                            }}
                          />
                        ),
                      }}
                    />
                  ),
                  direction: 'BOTTOM_LEFT',
                }}
              >
                <EventTrackerList />
              </Section>
              <TableSection>
                <EventTrackerTable />
              </TableSection>
            </Grid.Column>
          </Grid.Row>
        )}
        <RedirectPopup />
      </Page>
    </RedirectPopupProvider>
  );
};
