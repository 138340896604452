import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icons } from '@shopline/dashboard-ui';
import TextLabel from 'components/TextLabel';
import { mapValues } from 'lodash-es';
import imageUrlMap from './images';

const Container = styled.div`
  margin: 0 1rem 2.5rem;
  width: 7.5rem;
  position: relative;
`;

const mixinMap = mapValues(
  imageUrlMap,
  (imageUrl) => css`
    &::before {
      background-image: url('${imageUrl}');
    }
  `,
);

const TrackerImage = styled.div.attrs(({ to }) => ({
  ...(to ? { as: Link } : {}),
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: calc((100% / 6) - 1px) 0;
  border: 1px solid ${({ theme }) => theme.colors.INK_300};
  border-radius: 6px;
  transition-property: box-shadow;
  transition-duration: 100ms;
  transition-function: ease-in;
  cursor: pointer;
  &::before {
    display: block;
    content: ' ';
    width: calc((100% + 2px) * 2 / 3);
    padding-bottom: calc((100% + 2px) * 2 / 3);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.PRIMARY_600};
    box-shadow: ${({ theme }) => theme.shadows.SHADOW_400};
  }
  ${({ trackerKind }) => mixinMap[trackerKind]}
`;

const TrackerName = styled(TextLabel).attrs({
  fontType: 'Body',
  color: 'INK_900',
})`
  margin-top: 0.25rem;
  text-align: center;
  display: block;
`;

const InstalledIconContainer = styled.div`
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  pointer-events: none;
`;

export const EventTrackerKind = ({ kind, installed, to, onClick }) => {
  const { t } = useTranslation(['tracker']);
  return (
    <Container>
      <TrackerImage trackerKind={kind} to={to} onClick={onClick} />
      {installed && (
        <InstalledIconContainer>
          <Icons.Solid.CircleTick size="LARGE" color="GREEN_600" />
        </InstalledIconContainer>
      )}
      <TrackerName>{t('displayKind', { context: kind })}</TrackerName>
    </Container>
  );
};
