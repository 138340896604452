import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media, IconButton, Icons } from '@shopline/dashboard-ui';
import { Popover, Text } from 'components';
import { withProps } from 'utils/recompose';
import { PopoverPropType } from 'types/popover';
import FieldRow from './FieldRow';

const Container = styled.div`
  margin-top: 1.5rem;
  flex: 1 1 0;
  &:not(:first-child) {
    margin-left: 0.5rem;
  }
  ${FieldRow}:first-child > & {
    margin-top: 0;
  }
  ${media.mobile`
    flex: 1 0 100%;
    width: 100%;
    &:not(:first-child) {
      margin-left: 0;
    }
  `}
`;

const Heading = withProps({
  as: 'h4',
  fontType: 'Body',
  color: 'INK_800',
  weight: 'Semibold',
})(Text);

const Title = styled.div`
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
`;

const Field = ({ title, popoverProps, children }) => (
  <Container>
    <Title>
      <Heading>{title}</Heading>
      {popoverProps && <Popover {...popoverProps} />}
      {/* {hint && (
        <IconButton
          Icon={Icons.Outline.QuestionMark}
          iconProps={{ size: 'MEDIUM', color: 'INK_600' }}
          colorType="basic"
          size={1.5}
          tooltipProps={{
            text: hint,
            direction: 'BOTTOM_LEFT',
          }}
        />
      )} */}
    </Title>
    {children}
  </Container>
);
Field.propTypes = {
  title: PropTypes.string.isRequired,
  hint: PropTypes.string,
  popoverProps: PropTypes.shape(PopoverPropType),
  children: PropTypes.node.isRequired,
};

export default Field;
