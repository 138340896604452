import styled from 'styled-components';
import { Icons } from '@shopline/dashboard-ui';
import { Text } from 'components';
import { withProps } from 'utils/recompose';

export const List = styled.ul`
  margin: 0.5rem 0 0;
  padding-left: 0;
  list-style-type: none;
`;

export const Item = styled.li`
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

const LinkText = withProps({
  as: 'a',
})(Text);

export const Link = styled(LinkText).attrs({
  fontType: 'Body',
  color: 'PRIMARY_600',
  weight: 'Semibold',
  target: '_blank',
})`
  display: inline-flex;
  align-items: center;
`;

export const LinkItem = ({ linkProps, children, ...props }) => (
  <Item {...props}>
    <Link {...linkProps}>
      {children}
      <Icons.Solid.Connect size="MEDIUM" color="PRIMARY_600" />
    </Link>
  </Item>
);
