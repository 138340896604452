import { useContext } from 'react';
import { nanoid } from 'nanoid/non-secure';
import ToastContext from 'contexts/Toast';

export const useToast = (ns) => {
  const toastContext = useContext(ToastContext);

  if (!ns) {
    return toastContext;
  }
  return {
    ...toastContext,
    createUIToast: ({
      id: idOrUndefined,
      type,
      duration,
      title = true, // boolean | { params }
      description = false, // boolean | { nodeWithProps } | { params }
    }) => {
      const id = idOrUndefined ? idOrUndefined : nanoid();
      const titleProps = title
        ? {
            ...(title.params
              ? {
                  titleWithParams: {
                    params: title.params,
                    key: `${ns}:title`,
                  },
                }
              : {
                  title: `${ns}:title`,
                }),
          }
        : {};
      const descriptionProps = description
        ? {
            ...(description.nodeWithProps
              ? { descriptionNodeWithProps: description.nodeWithProps }
              : {
                  ...(description.params
                    ? {
                        descriptionWithParams: {
                          params: description.params,
                          key: `${ns}:description`,
                        },
                      }
                    : {
                        description: `${ns}:description`,
                      }),
                }),
          }
        : {};
      const newToast = {
        id,
        type,
        duration,
        ...titleProps,
        ...descriptionProps,
      };
      return toastContext.createUIToast(newToast);
    },
  };
};
