import styled from 'styled-components';
import * as trackerKinds from 'constants/trackerKinds';
import { TIKTOK, LINE_POINTS } from 'constants/featureKeys';
import {
  GeneralEventTrackerKind,
  RedirectEventTrackerKind,
} from './components';
import { useFeatureControl } from 'hooks/useFeaturesControl';

const Container = styled.div`
  flex: 0 0 100%;
  flex-wrap: wrap;
  justify-content: flex-starr;
  display: flex;
  margin-top: 1rem;
  padding: 0 0.5rem;
`;

const EventTrackerList = () => {
  const tiktokEnabled = useFeatureControl(TIKTOK);
  const linePointsEnabled = useFeatureControl(LINE_POINTS);
  return (
    <Container>
      <GeneralEventTrackerKind kind={trackerKinds.GA4} />
      <GeneralEventTrackerKind kind={trackerKinds.GA} />
      <GeneralEventTrackerKind kind={trackerKinds.GTM} />
      <GeneralEventTrackerKind kind={trackerKinds.G_ADS} />
      <GeneralEventTrackerKind kind={trackerKinds.G_REMARKETING} />
      <GeneralEventTrackerKind kind={trackerKinds.YAHOO} />
      <GeneralEventTrackerKind kind={trackerKinds.BING} />
      <RedirectEventTrackerKind kind={trackerKinds.META} />
      {false && <GeneralEventTrackerKind kind={trackerKinds.CRITEO} />}
      <GeneralEventTrackerKind kind={trackerKinds.LINE} />
      {linePointsEnabled && (
        <GeneralEventTrackerKind kind={trackerKinds.LINE_POINTS} />
      )}
      {tiktokEnabled && <RedirectEventTrackerKind kind={trackerKinds.TIKTOK} />}
    </Container>
  );
};

export default EventTrackerList;
