import { useCallback, useState } from 'react';
import { constant, negate, identity, stubTrue } from 'lodash-es';
import { TagSelector } from '@shopline/dashboard-ui';
import { useOptions } from './useOptions';

const stubNull = constant(null);

export const TrackerEventSelector = ({
  value,
  placeholder,
  onFocus,
  onChange,
  options,
  errorMessage,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => {
    if (disabled) {
      return;
    }
    onFocus();
    setOpen(negate(identity));
  }, [setOpen, disabled, onFocus]);
  const close = useCallback(() => {
    if (disabled) {
      return;
    }
    setOpen(false);
  }, [setOpen, disabled]);
  const memoizedOptions = useOptions({ options });
  const hasError = errorMessage !== '';
  return (
    <TagSelector
      width="100%"
      tags={value}
      options={memoizedOptions}
      placeHolder={placeholder}
      onTagChange={onChange}
      onValidation={stubTrue}
      hasError={hasError}
      renderErrorMessage={stubNull}
      isOpen={open}
      onClick={toggle}
      onClickOutside={close}
      disabled={disabled}
    />
  );
};
