import { useState, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  cond,
  constant,
  flatten,
  mapValues,
  omit,
  has,
  identity,
  iteratee,
  stubTrue,
} from 'lodash-es';
import prop from 'lodash/fp/prop';
import { mapProps } from 'utils/recompose';
import { Link } from 'react-router-dom';
import { IconButton, Icons, Button } from '@shopline/dashboard-ui';
import { usePermissions } from 'hooks/usePermissions';
import { useStaff } from 'hooks/useStaff';
import { useToast } from 'hooks/useToast';
import { useQueryTrackers, useRemoveTracker } from 'hooks/useTrackers';
import { useFeatureControl } from 'hooks/useFeaturesControl';
import * as trackerKinds from 'constants/trackerKinds';
import { LINE_POINTS } from 'constants/featureKeys';
import { useRedirectPopupContext } from './components';
import { redirectKinds, redirectBehaviors } from './components/RedirectPopup';
import imageUrlMap from './components/EventTrackerKind/images';

const KEY_PREFIX = 'EVENT_TRACKER_TABLE';
const KEYS = {
  KIND: `${KEY_PREFIX}.KIND`,
  EVENT_TYPES: `${KEY_PREFIX}.EVENT_TYPES`,
  CODE: `${KEY_PREFIX}.CODE`,
  ACTIONS: `${KEY_PREFIX}.ACTIONS`,
};
const CODE_WIDTH = 12;
const COLUMNS = [
  { key: KEYS.KIND, width: 25 },
  { key: KEYS.EVENT_TYPES, width: 15 },
  { key: KEYS.CODE, width: CODE_WIDTH },
  { key: KEYS.ACTIONS, width: 9 },
];

const invalidTrackerKinds = [
  trackerKinds.LEGACY_FB_PIXEL,
  trackerKinds.LEGACY_FB_AUDIENCE,
];

const redirectKindMap = {
  [trackerKinds.META]: redirectKinds.FACEBOOK_ENTRANCE,
  [trackerKinds.TIKTOK]: redirectKinds.TT4B,
};

const mixinMap = mapValues(
  imageUrlMap,
  (imageUrl) => css`
    &::before {
      background-image: url('${imageUrl}');
    }
  `,
);

const EventTrackerKind = styled.p`
  display: inline-flex;
  align-items: center;
  &::before {
    display: block;
    content: ' ';
    width: 20px;
    height: 20px;
    margin-right: 0.25rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  ${({ trackerKind }) => mixinMap[trackerKind]}
`;

const EventTrackerCode = styled.div`
  max-width: ${CODE_WIDTH}rem;
  word-break: break-all;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const OutlinePrimaryLinkButton = mapProps(
  ({ isLink, isDisabled, ...props }) => ({
    ...props,
    isDisabled,
    ...(isLink && !isDisabled ? { as: Link } : {}),
  }),
)(Button.OutlinePrimary);

const LinkButton = styled(OutlinePrimaryLinkButton).attrs({
  height: 'SMALL',
})`
  margin-left: 1rem;
`;

const removePopupInitialState = {
  open: false,
  id: null,
  kind: null,
  token: null,
  destructiveValues: {},
};

export const getColumnContext = cond([
  [iteratee({ linePointsEnabled: true }), constant(LINE_POINTS)],
  [stubTrue, constant('')],
]);

export const useEventTrackerTable = () => {
  const { t } = useTranslation('main', 'tracker', 'common');
  const { merchantId } = useParams();
  const { createUIToast: createRemoveToast } = useToast('removeSuccessToast');
  const { open: openRedirectPopup } = useRedirectPopupContext();
  const [removePopup, setRemovePopup] = useState(removePopupInitialState);
  const linePointsEnabled = useFeatureControl(LINE_POINTS);
  const canEdit = usePermissions('eventTrackers', 'edit'); // permission;
  const canRemove = usePermissions('eventTrackers', 'remove'); // permission;
  const canEditIsShoplineTracker = useStaff(['isSuperAdmin', 'isMarketer']);
  const canRemoveIsShoplineTracker = useStaff(['isSuperAdmin', 'isMarketer']);
  const { data, isLoading, isSuccess } = useQueryTrackers();
  const { mutate: removeTracker, isLoading: submitting } = useRemoveTracker({
    onSuccess: (data, { kind }) => {
      createRemoveToast({
        type: 'success',
        duration: 5000,
        title: {
          params: {
            context: kind,
          },
        },
      });
    },
  });
  const openRedirectPopupWithKind = useCallback(
    ({ kind, behavior }) => {
      const redirectKind = redirectKindMap[kind];
      if (redirectKind) {
        openRedirectPopup({ kind: redirectKind, behavior });
      }
    },
    [openRedirectPopup],
  );
  const openRemovePopup = useCallback(
    ({ id, kind, token }) => {
      setRemovePopup((state) => ({
        ...state,
        open: true,
        id,
        kind,
        token,
      }));
    },
    [setRemovePopup],
  );
  const closeRemovePopup = useCallback(() => {
    setRemovePopup((state) => ({
      ...state,
      open: false,
      id: null,
      kind: null,
      token: null,
    }));
  }, [setRemovePopup]);
  const mountDestructiveItem = useCallback(
    (key, isMount = true) => {
      setRemovePopup(({ destructiveValues, ...state }) => ({
        ...state,
        destructiveValues: isMount
          ? {
              ...destructiveValues,
              [key]: false,
            }
          : omit(destructiveValues, key),
      }));
    },
    [setRemovePopup],
  );
  const toggleDestructiveItem = useCallback(
    (key) => {
      if (has(removePopup.destructiveValues, key)) {
        setRemovePopup(({ destructiveValues, ...state }) => ({
          ...state,
          destructiveValues: {
            ...destructiveValues,
            [key]: !destructiveValues[key],
          },
        }));
      }
    },
    [setRemovePopup, removePopup.destructiveValues],
  );
  const handleRemoveTracker = useCallback(async () => {
    await removeTracker({
      id: removePopup.id,
      kind: removePopup.kind,
      token: removePopup.token,
    });
    setRemovePopup(removePopupInitialState);
  }, [removePopup.id, removePopup.kind, removePopup.token, removeTracker]);
  const renderActions = useCallback(
    ({ id, kind, isFbe, isShopline }) => {
      const shouldRedirect =
        kind === trackerKinds.TIKTOK || (kind === trackerKinds.META && isFbe);

      const canEditCurrentTracker = isShopline
        ? canEditIsShoplineTracker
        : canEdit;

      const canRemoveCurrentTracker = isShopline
        ? canRemoveIsShoplineTracker
        : canRemove;

      return (
        <ActionContainer>
          <IconButton
            Icon={Icons.Solid.Trash}
            iconProps={{ size: 'LARGE' }}
            colorType="basic"
            size={1.5}
            isDisabled={!canRemoveCurrentTracker && !shouldRedirect}
            data-btn-action={
              shouldRedirect ? 'redirect-remove' : 'remove-tracker'
            }
            tooltipProps={{
              text: t('Remove', { ns: 'common' }),
              direction: 'TOP_CENTER',
            }}
          />
          {canEditCurrentTracker || shouldRedirect ? (
            <LinkButton
              isLink={!shouldRedirect}
              isDisabled={invalidTrackerKinds.includes(kind)}
              data-btn-action={
                shouldRedirect ? 'redirect-edit' : 'edit-tracker'
              }
              to={{
                pathname: `/${merchantId}/trackers/${id}/edit`,
              }}
            >
              {t('Edit', { ns: 'common' })}
            </LinkButton>
          ) : (
            <LinkButton
              isLink
              isDisabled={invalidTrackerKinds.includes(kind)}
              data-btn-action="view-tracker"
              to={{
                pathname: `/${merchantId}/trackers/${id}`,
              }}
            >
              {t('View', { ns: 'common' })}
            </LinkButton>
          )}
        </ActionContainer>
      );
    },
    [
      t,
      merchantId,
      canEdit,
      canEditIsShoplineTracker,
      canRemove,
      canRemoveIsShoplineTracker,
    ],
  );
  const handleTableRowClick = useCallback(
    (event, { id, kind, token, isShopline }) => {
      const canRemoveCurrentTracker = isShopline
        ? canRemoveIsShoplineTracker
        : canRemove;

      if (event.target.closest('[data-btn-action=redirect-remove]')) {
        openRedirectPopupWithKind({ kind, behavior: redirectBehaviors.REMOVE });
      }
      if (event.target.closest('[data-btn-action=redirect-edit]')) {
        openRedirectPopupWithKind({ kind, behavior: redirectBehaviors.EDIT });
      }
      if (
        event.target.closest('[data-btn-action=remove-tracker]') &&
        canRemoveCurrentTracker
      ) {
        openRemovePopup({ id, kind, token });
      }
    },
    [
      canRemove,
      canRemoveIsShoplineTracker,
      openRemovePopup,
      openRedirectPopupWithKind,
    ],
  );
  return useMemo(() => {
    const flattenItems = isSuccess
      ? flatten(data.pages.map(prop('items')))
      : [];
    return {
      isLoading,
      isRemovePopupOpen: removePopup.open,
      destructiveValues: removePopup.destructiveValues,
      isAllDestructiveChecked: Object.values(
        removePopup.destructiveValues,
      ).every(identity),
      isRemoveSubmitting: submitting,
      handleTableRowClick,
      closeRemovePopup,
      mountDestructiveItem,
      toggleDestructiveItem,
      removeTracker: handleRemoveTracker,
      columns: COLUMNS.map((column) => ({
        ...column,
        align: column.key === KEYS.ACTIONS ? 'right' : 'left',
        title: t(column.key, {
          context: getColumnContext({ linePointsEnabled }),
        }),
      })),
      dataSources: isLoading
        ? []
        : flattenItems.map(
            ({
              id,
              kind,
              token,
              eventTypes,
              configData: {
                trackingCode,
                trackingCode2,
                isFbe,
                isShopline,
                conversions,
              },
            }) => ({
              id,
              kind,
              token,
              eventTypes,
              trackingCode,
              isFbe: isFbe ?? false,
              isShopline: isShopline ?? false,
              [KEYS.KIND]: (
                <EventTrackerKind trackerKind={kind}>
                  {t('displayKind', { ns: 'tracker', context: kind })}
                </EventTrackerKind>
              ),
              [KEYS.EVENT_TYPES]:
                kind === trackerKinds.LINE_POINTS ? (
                  <>
                    {conversions.map((mission, index) => (
                      <>
                        {index !== 0 && <br />}
                        {t(`label.${trackerKinds.LINE_POINTS}.missions`, {
                          ns: 'tracker',
                          context: mission,
                        })}
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    {eventTypes.map((eventType, index) => (
                      <>
                        {index !== 0 && <br />}
                        {t('displayEvent', {
                          ns: 'tracker',
                          context: eventType,
                        })}
                      </>
                    ))}
                  </>
                ),
              [KEYS.CODE]: (
                <EventTrackerCode>
                  {trackingCode}
                  {trackingCode2 && (
                    <>
                      <br />
                      {trackingCode2}
                    </>
                  )}
                </EventTrackerCode>
              ),
              [KEYS.ACTIONS]: renderActions,
            }),
          ),
    };
  }, [
    t,
    isSuccess,
    isLoading,
    removePopup,
    mountDestructiveItem,
    toggleDestructiveItem,
    submitting,
    data?.pages,
    renderActions,
    handleTableRowClick,
    closeRemovePopup,
    handleRemoveTracker,
    linePointsEnabled,
  ]);
};
