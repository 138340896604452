import { useTranslation, Trans } from 'react-i18next';
import { Table, Popup, Button } from '@shopline/dashboard-ui';
import { DestructivePopupCheckbox } from 'features/components';
import { useEventTrackerTable } from './useEventTrackerTable';

export const EventTrackerTable = () => {
  const { t } = useTranslation(['removeTrackerPopup', 'commonPopup']);
  const {
    isLoading,
    columns,
    dataSources,
    handleTableRowClick,
    isRemovePopupOpen,
    isAllDestructiveChecked,
    destructiveValues,
    isRemoveSubmitting,
    closeRemovePopup,
    mountDestructiveItem,
    toggleDestructiveItem,
    removeTracker,
  } = useEventTrackerTable();
  if (isLoading) {
    return null;
  }
  return (
    <>
      <Table
        headColumnAlign="left"
        rowVerticalAlign="middle"
        columns={columns}
        dataSource={dataSources}
        onTableRowClick={handleTableRowClick}
      />
      {isRemovePopupOpen && (
        <Popup
          title={t('title')}
          onClose={closeRemovePopup}
          renderFooter={() => (
            <>
              <Button.Default onClick={closeRemovePopup} height="LARGE">
                {t('cancel')}
              </Button.Default>
              <Button.Destructive
                onClick={removeTracker}
                disabled={isRemoveSubmitting || !isAllDestructiveChecked}
                height="LARGE"
              >
                {t('ok')}
              </Button.Destructive>
            </>
          )}
        >
          <Trans
            i18nKey="content"
            t={t}
            components={{
              br: <br />,
              checkbox: (
                <DestructivePopupCheckbox
                  values={destructiveValues}
                  onMount={mountDestructiveItem}
                  onToggle={toggleDestructiveItem}
                />
              ),
            }}
          />
        </Popup>
      )}
    </>
  );
};
