import { Children } from 'react';
import styled from 'styled-components';
import { media } from '@shopline/dashboard-ui';
import { Text } from 'components';
import { withProps } from 'utils/recompose';

const Container = styled.div`
  margin: -1rem 0 1rem;
  ${media.mobile`
    padding: 0 1rem;
  `};
`;

const TextParagraph = withProps({ as: 'p' })(Text);

const StyledText = styled(TextParagraph).attrs({
  fontType: 'Body',
  color: 'INK_600',
})`
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

const PageDescription = ({ children }) => (
  <Container>
    {Children.map(children, (child) => (
      <StyledText>{child}</StyledText>
    ))}
  </Container>
);

export default PageDescription;
