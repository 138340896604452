import { useTranslation } from 'react-i18next';
import { stubTrue, stubFalse } from 'lodash-es';
import getOr from 'lodash/fp/getOr';
import prop from 'lodash/fp/prop';
import { RadioButton } from '@shopline/dashboard-ui';
import { FieldRow, Field, RadioButtonsContainer } from 'features/components';
import { META } from 'constants/trackerKinds';
import { BaseTrackerForm } from './BaseTrackerForm';

export const additionalDefaultValuesMap = {
  useUniqueId: getOr(false, ['configData', 'uniqueIds']),
  singleVariation: getOr(false, ['configData', 'singleVariation']),
};

export const additionalConfigDataMap = {
  uniqueIds: prop('useUniqueId'),
  singleVariation: prop('singleVariation'),
};

export const MetaTrackerForm = (props) => {
  const { t } = useTranslation('tracker');
  return (
    <BaseTrackerForm
      {...props}
      additionalDefaultValuesMap={additionalDefaultValuesMap}
      additionalConfigDataMap={additionalConfigDataMap}
    >
      {({ form, meta }) => (
        <>
          <FieldRow>
            <Field title={t(`field.${META}.useUniqueId`)}>
              <RadioButtonsContainer>
                <RadioButton
                  disabled={props.readonly}
                  checked={form.useUniqueId}
                  label={t(`label.${META}.useUniqueId`, {
                    context: 'productId',
                  })}
                  onChange={() => {
                    meta.handlers.useUniqueId.onFocus();
                    meta.handlers.useUniqueId.onChange(stubTrue);
                  }}
                />
                <RadioButton
                  disabled={props.readonly}
                  checked={!form.useUniqueId}
                  label={t(`label.${META}.useUniqueId`, {
                    context: 'sku',
                  })}
                  onChange={() => {
                    meta.handlers.useUniqueId.onFocus();
                    meta.handlers.useUniqueId.onChange(stubFalse);
                  }}
                />
              </RadioButtonsContainer>
            </Field>
          </FieldRow>
          <FieldRow>
            <Field title={t(`field.${META}.singleVariation`)}>
              <RadioButtonsContainer>
                <RadioButton
                  disabled={props.readonly}
                  checked={form.singleVariation}
                  label={t(`label.${META}.singleVariation`, {
                    context: 'showOne',
                  })}
                  onChange={() => {
                    meta.handlers.singleVariation.onFocus();
                    meta.handlers.singleVariation.onChange(stubTrue);
                  }}
                />
                <RadioButton
                  disabled={props.readonly}
                  checked={!form.singleVariation}
                  label={t(`label.${META}.singleVariation`, {
                    context: 'showAll',
                  })}
                  onChange={() => {
                    meta.handlers.singleVariation.onFocus();
                    meta.handlers.singleVariation.onChange(stubFalse);
                  }}
                />
              </RadioButtonsContainer>
            </Field>
          </FieldRow>
        </>
      )}
    </BaseTrackerForm>
  );
};
