import { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Icons } from '@shopline/dashboard-ui';
import { Text } from 'components';
import { withProps } from 'utils/recompose';
import { FieldRow, Field } from 'features/components';
import { GA4 } from 'constants/trackerKinds';
import { BaseTrackerForm } from './BaseTrackerForm';

export const LinkText = withProps({ as: 'a', target: '_blank' })(Text);

export const Link = styled(LinkText).attrs({
  fontType: 'Body',
  color: 'PRIMARY_600',
  weight: 'Semibold',
})`
  display: inline-flex;
  align-items: center;
`;

export const Ga4TrackerForm = (props) => {
  const { t } = useTranslation('tracker');
  const popoverProps = useMemo(
    () => ({
      direction: 'BOTTOM_LEFT',
      title: t('tipsPopover.title'),
      content: t(`placeholder.${GA4}.enhancedEC`),
    }),
    [t],
  );
  return (
    <BaseTrackerForm {...props}>
      {() => (
        <FieldRow>
          <Field
            title={t(`field.${GA4}.enhancedEC`)}
            popoverProps={popoverProps}
          >
            <Link
              href={t(`link.${GA4}.enhancedEC`)}
              fontType="Body"
              color="PRIMARY_600"
            >
              {t(`label.${GA4}.enhancedEC`)}
              <Icons.Outline.Link size="MEDIUM" color="PRIMARY_600" />
            </Link>
          </Field>
        </FieldRow>
      )}
    </BaseTrackerForm>
  );
};
