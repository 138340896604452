export const common = {
  Test: 'Test',
  Save: 'Save',
  Add: 'Add',
  Edit: 'Edit',
  View: 'View',
  Return: 'Return',
  Cancel: 'Cancel',
  Cancel_return: '$t(Return)',
  Remove: 'Remove',
  Contact: 'Contact Us',
};

export default common;
