import { useTranslation } from 'react-i18next';
import { negate, identity, stubTrue, stubFalse } from 'lodash-es';
import getOr from 'lodash/fp/getOr';
import prop from 'lodash/fp/prop';
import { Checkbox, RadioButton } from '@shopline/dashboard-ui';
import {
  FieldRow,
  Field,
  Toggle,
  RadioButtonsContainer,
} from 'features/components';
import { G_REMARKETING } from 'constants/trackerKinds';
import { useStaff } from 'hooks/useStaff';
import { BaseTrackerForm } from './BaseTrackerForm';

export const additionalDefaultValuesMap = {
  isShopline: getOr(false, ['configData', 'isShopline']),
  dynamic: getOr(false, ['configData', 'dynamicRemarketing']),
  useUniqueId: getOr(false, ['configData', 'uniqueIds']),
  singleVariation: getOr(false, ['configData', 'singleVariation']),
};

export const additionalConfigDataMap = {
  isShopline: prop('isShopline'),
  dynamicRemarketing: prop('dynamic'),
  uniqueIds: prop('useUniqueId'),
  singleVariation: prop('singleVariation'),
};

export const GRemarketingTrackerForm = (props) => {
  const { t } = useTranslation('tracker');
  const isShoplineStaff = useStaff([
    'isSuperAdmin',
    'isMarketer',
    'isInspector',
  ]);
  const canEditAdvancedSetting = useStaff(['isSuperAdmin', 'isMarketer']);
  return (
    <BaseTrackerForm
      {...props}
      allowOtherEvents
      additionalDefaultValuesMap={additionalDefaultValuesMap}
      additionalConfigDataMap={additionalConfigDataMap}
    >
      {({ form, meta }) =>
        isShoplineStaff && (
          <>
            <FieldRow>
              <Field title={t(`field.${G_REMARKETING}.dynamic`)}>
                <Toggle
                  disabled={props.readonly || !canEditAdvancedSetting}
                  label={t(`label.${G_REMARKETING}.dynamic`)}
                  value={form.dynamic}
                  onClick={() => {
                    meta.handlers.dynamic.onFocus();
                    meta.handlers.dynamic.onChange(negate(identity));
                  }}
                />
              </Field>
            </FieldRow>
            <FieldRow>
              <Field title={t(`field.${G_REMARKETING}.useUniqueId`)}>
                <RadioButtonsContainer>
                  <RadioButton
                    disabled={
                      props.readonly || !canEditAdvancedSetting || !form.dynamic
                    }
                    checked={form.useUniqueId}
                    label={t(`label.${G_REMARKETING}.useUniqueId`, {
                      context: 'productId',
                    })}
                    onChange={() => {
                      meta.handlers.useUniqueId.onFocus();
                      meta.handlers.useUniqueId.onChange(stubTrue);
                    }}
                  />
                  <RadioButton
                    disabled={
                      props.readonly || !canEditAdvancedSetting || !form.dynamic
                    }
                    checked={!form.useUniqueId}
                    label={t(`label.${G_REMARKETING}.useUniqueId`, {
                      context: 'sku',
                    })}
                    onChange={() => {
                      meta.handlers.useUniqueId.onFocus();
                      meta.handlers.useUniqueId.onChange(stubFalse);
                    }}
                  />
                </RadioButtonsContainer>
              </Field>
            </FieldRow>
            <FieldRow>
              <Field title={t(`field.${G_REMARKETING}.singleVariation`)}>
                <RadioButtonsContainer>
                  <RadioButton
                    disabled={
                      props.readonly || !canEditAdvancedSetting || !form.dynamic
                    }
                    checked={form.singleVariation}
                    label={t(`label.${G_REMARKETING}.singleVariation`, {
                      context: 'showOne',
                    })}
                    onChange={() => {
                      meta.handlers.singleVariation.onFocus();
                      meta.handlers.singleVariation.onChange(stubTrue);
                    }}
                  />
                  <RadioButton
                    disabled={
                      props.readonly || !canEditAdvancedSetting || !form.dynamic
                    }
                    checked={!form.singleVariation}
                    label={t(`label.${G_REMARKETING}.singleVariation`, {
                      context: 'showAll',
                    })}
                    onChange={() => {
                      meta.handlers.singleVariation.onFocus();
                      meta.handlers.singleVariation.onChange(stubFalse);
                    }}
                  />
                </RadioButtonsContainer>
              </Field>
            </FieldRow>
            <FieldRow>
              <Field title={t(`field.${G_REMARKETING}.isShopline`)}>
                <Checkbox.Default
                  disabled={props.readonly || !canEditAdvancedSetting}
                  label={t(`label.${G_REMARKETING}.isShopline`)}
                  checked={form.isShopline}
                  onChange={() => {
                    meta.handlers.isShopline.onFocus();
                    meta.handlers.isShopline.onChange(negate(identity));
                  }}
                />
              </Field>
            </FieldRow>
          </>
        )
      }
    </BaseTrackerForm>
  );
};
