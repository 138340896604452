import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useOptions = ({ options }) => {
  const { t } = useTranslation('tracker');
  return useMemo(
    () =>
      options.map((val) => ({
        key: val,
        value: val,
        label: t('displayEvent', { context: val }),
      })),
    [t, options],
  );
};
