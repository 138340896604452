import { usePermissions } from 'hooks/usePermissions';
import { useStaff } from 'hooks/useStaff';
import { useIsCreate } from '../../useIsCreate';

export const useCanSaveCurrentTracker = ({ isShopline }) => {
  const isCreate = useIsCreate();
  const canCreate = usePermissions('eventTrackers', 'create');
  const canEdit = usePermissions('eventTrackers', 'edit');
  const canSave = isCreate ? canCreate : canEdit;
  const canSaveIsShoplineTracker = useStaff(['isSuperAdmin', 'isMarketer']);

  return isShopline ? canSaveIsShoplineTracker : canSave;
};
