import axios from 'axios';
import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from 'react-query';
import { useParams } from 'react-router-dom';
import {
  cond,
  constant,
  identity,
  invert,
  curry,
  iteratee,
  negate,
  stubTrue,
} from 'lodash-es';
import * as trackerKinds from 'constants/trackerKinds';

const trackerTypeMap = {
  [trackerKinds.GA]: 'google_analytics',
  [trackerKinds.GA4]: 'google_analytics_4',
  [trackerKinds.GTM]: 'google_tag',
  [trackerKinds.G_ADS]: 'google_adwords',
  [trackerKinds.G_REMARKETING]: 'google_remarketing',
  [trackerKinds.META]: 'facebook_standard_pixel',
  [trackerKinds.YAHOO]: 'yahoo',
  [trackerKinds.BING]: 'bing',
  [trackerKinds.CRITEO]: 'criteo',
  [trackerKinds.LINE]: 'line_lap',
  [trackerKinds.LINE_POINTS]: 'line_points_ads',
  [trackerKinds.TIKTOK]: 'tiktok',
  // custom
  [trackerKinds.CUSTOM]: 'custom',
  // legacy
  [trackerKinds.LEGACY_FB_PIXEL]: 'facebook_pixel',
  [trackerKinds.LEGACY_FB_AUDIENCE]: 'facebook_audience',
};
const invertedTrackerTypeMap = invert(trackerTypeMap);

const transformTracker = (tracker) =>
  Object.assign(tracker, {
    kind: invertedTrackerTypeMap[tracker.trackerType],
  });

const getTrackers = async (merchantId, nextPage = 1) => {
  const { data } = await axios.get(
    `/api/merchants/${merchantId}/event-trackers`,
    {
      params: {
        page: nextPage,
        perPage: 50,
      },
    },
  );

  return {
    pagination: data.pagination,
    items: data.items.map(transformTracker),
  };
};

const createTracker = async (merchantId, { kind, tracker }) => {
  const { data } = await axios.post(
    `/api/merchants/${merchantId}/event-trackers`,
    {
      ...tracker,
      trackerType: trackerTypeMap[kind],
    },
  );
  return data;
};

const getTracker = async (merchantId, trackerId) => {
  const { data } = await axios.get(
    `/api/merchants/${merchantId}/event-trackers/${trackerId}`,
  );

  return transformTracker(data);
};

const patchTracker = async (merchantId, { id: trackerId, tracker, token }) => {
  const { data } = await axios.patch(
    `/api/merchants/${merchantId}/event-trackers/${trackerId}`,
    tracker,
    { params: { token } },
  );
  return data;
};

const removeTracker = async (merchantId, { token }) => {
  const { data } = await axios.delete(
    `/api/merchants/${merchantId}/event-trackers/${token}`,
  );
  return data;
};

const curriedCreateTracker = curry(createTracker, 2);
const curriedPatchTracker = curry(patchTracker, 2);
const curriedRemoveTracker = curry(removeTracker, 2);

const passMerchantId =
  (queryFn) =>
  ({ queryKey: [, merchantId], pageParam }) =>
    queryFn(merchantId, pageParam);

const passMerchantIdAndTrackerId =
  (queryFn) =>
  ({ queryKey: [, merchantId, trackerId] }) =>
    queryFn(merchantId, trackerId);

const composeQueryListKey = (merchantId) => ['trackers', merchantId];
const composeQueryItemKey = (merchantId, trackerId) => [
  'tracker',
  merchantId,
  trackerId,
];

export const useQueryTrackers = () => {
  const { merchantId } = useParams();
  return useInfiniteQuery(
    composeQueryListKey(merchantId),
    passMerchantId(getTrackers),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.currentPage + 1;
        if (nextPage > lastPage.currentPage) {
          return undefined;
        }
        return nextPage;
      },
    },
  );
};

export const useCreateTracker = ({ onSuccess } = {}) => {
  const { merchantId } = useParams();
  const queryClient = useQueryClient();
  return useMutation(curriedCreateTracker(merchantId), {
    onSuccess: (...args) => {
      const [data] = args;
      const tracker = transformTracker(data);
      const listKey = composeQueryListKey(merchantId);
      if (queryClient.getQueryData(listKey)) {
        queryClient.setQueryData(composeQueryListKey(merchantId), (cache) => {
          return {
            pageParam: cache.pageParam,
            pages: cache.pages.map((page, index) => ({
              pagination: {
                ...page.pagination,
                totalCount: page.pagination.totalCount + 1,
                totalPages:
                  page.pagination.totalPages === 0
                    ? 1
                    : page.pagination.totalPages,
              },
              items:
                index < cache.pages.length - 1
                  ? page.items
                  : page.items.concat([tracker]),
            })),
          };
        });
      }
      if (typeof onSuccess === 'function') {
        onSuccess(...args);
      }
    },
  });
};

export const useQueryTracker = ({ trackerId }) => {
  const { merchantId } = useParams();
  return useQuery(
    composeQueryItemKey(merchantId, trackerId),
    passMerchantIdAndTrackerId(getTracker),
    {
      enabled: !!trackerId,
    },
  );
};

export const usePatchTracker = ({ onSuccess } = {}) => {
  const { merchantId } = useParams();
  const queryClient = useQueryClient();
  return useMutation(curriedPatchTracker(merchantId), {
    onSuccess: (...args) => {
      const [data] = args;
      const { id } = data;
      const tracker = transformTracker(data);
      const listKey = composeQueryListKey(merchantId);
      if (queryClient.getQueryData(listKey)) {
        queryClient.setQueryData(composeQueryListKey(merchantId), (cache) => ({
          pageParam: cache.pageParam,
          pages: cache.pages.map((page) => ({
            pagination: page.pagination,
            items: page.items.map(
              cond([
                [iteratee({ id }), constant(tracker)],
                [stubTrue, identity],
              ]),
            ),
          })),
        }));
      }
      const itemKey = composeQueryItemKey(merchantId, id);
      if (queryClient.getQueryData(itemKey)) {
        queryClient.setQueryData(composeQueryItemKey(merchantId, id), tracker);
      }
      if (typeof onSuccess === 'function') {
        onSuccess(...args);
      }
    },
  });
};

export const useRemoveTracker = ({ onSuccess } = {}) => {
  const { merchantId } = useParams();
  const queryClient = useQueryClient();
  return useMutation(curriedRemoveTracker(merchantId), {
    onSuccess: (...args) => {
      const [, { id }] = args;
      queryClient.setQueryData(composeQueryListKey(merchantId), (cache) => ({
        pageParam: cache.pageParam,
        pages: cache.pages.map((page) => ({
          pagination: page.pagination,
          items: page.items.filter(negate(iteratee({ id }))),
        })),
      }));
      if (typeof onSuccess === 'function') {
        onSuccess(...args);
      }
    },
  });
};
