import { createStructuredSelector, createSelector } from 'reselect';
import { negate } from 'lodash-es';
import prop from 'lodash/fp/prop';
import getOr from 'lodash/fp/getOr';
import filter from 'lodash/fp/filter';
import eq from 'lodash/fp/eq';
import some from 'lodash/fp/some';
import { LOADED_ANY_PAGE } from 'constants/trackerEvents';

const eventTypesSelector = getOr([LOADED_ANY_PAGE], 'eventTypes');

const eventsSelector = createSelector(
  eventTypesSelector,
  filter(negate(eq(LOADED_ANY_PAGE))),
);

const isLoadedAnyPageSelector = createSelector(
  eventTypesSelector,
  some(eq(LOADED_ANY_PAGE)),
);

export const createDefaultValuesSelector = (selectorMap = {}) =>
  createStructuredSelector({
    id: prop('id'),
    kind: prop('kind'),
    token: prop('token'),
    code: getOr('', ['configData', 'trackingCode']),
    html: prop('trackingHtml'),
    events: eventsSelector,
    isLoadedAnyPage: isLoadedAnyPageSelector,
    ...selectorMap,
  });
