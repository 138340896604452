import { useCallback } from 'react';
import { TextArea as BaseTextArea } from '@shopline/dashboard-ui';

const Textarea = ({ onChange, ...props }) => {
  const handleChange = useCallback(
    ({ target: { value } }) => {
      if (typeof onChange === 'function') {
        onChange(value);
      }
    },
    [onChange],
  );
  return <BaseTextArea {...props} onChange={handleChange} />;
};

export default Textarea;
