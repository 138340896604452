import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { withProps } from 'utils/recompose';

export const useBreadcrumb = ({ e2ePrefix, isCreate }) => {
  const { t } = useTranslation('pageTitle');
  const { merchantId } = useParams();

  const breadcrumbs = useMemo(
    () => [
      {
        id: t('Marketing & Tracker'),
        label: t('Marketing & Tracker'),
        e2eId: `${e2ePrefix}-marketing-tracker`,
      },
      {
        id: t('Tracker Settings'),
        label: t('Tracker Settings'),
        e2eId: `${e2ePrefix}-tracker-settings`,
        component: withProps({ to: { pathname: `/${merchantId}/trackers` } })(
          Link,
        ),
      },
      {
        id: t('Add Event Tracker', { context: isCreate ? '' : 'edit' }),
        label: t('Add Event Tracker', { context: isCreate ? '' : 'edit' }),
        e2eId: `${e2ePrefix}-tracker-editor`,
      },
    ],
    [e2ePrefix, t, isCreate, merchantId],
  );

  return breadcrumbs;
};
