import * as trackerKinds from 'constants/trackerKinds';
import { default as bing } from './bing.svg';
import { default as criteo } from './criteo.svg';
import { default as ga } from './ga.svg';
import { default as ga4 } from './ga4.svg';
import { default as gAds } from './gAds.svg';
import { default as gRemarketing } from './gRemarketing.svg';
import { default as gtm } from './gtm.svg';
import { default as line } from './line.svg';
import { default as meta } from './meta.svg';
import { default as tiktok } from './tiktok.svg';
import { default as yahoo } from './yahoo.svg';

const images = {
  [trackerKinds.BING]: bing,
  [trackerKinds.CRITEO]: criteo,
  [trackerKinds.GA]: ga,
  [trackerKinds.GA4]: ga4,
  [trackerKinds.G_ADS]: gAds,
  [trackerKinds.G_REMARKETING]: gRemarketing,
  [trackerKinds.GTM]: gtm,
  [trackerKinds.LINE]: line,
  [trackerKinds.LINE_POINTS]: line,
  [trackerKinds.META]: meta,
  [trackerKinds.TIKTOK]: tiktok,
  [trackerKinds.YAHOO]: yahoo,
};

export default images;
