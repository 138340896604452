export const common = {
  Test: '測試',
  Save: '储存',
  Add: '新增',
  Edit: '编辑',
  View: '检视',
  Return: '返回',
  Cancel: '舍弃',
  Cancel_return: '$t(Return)',
  Remove: '删除',
  Contact: '联系顾问',
};
