import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useBreadcrumb = ({ e2ePrefix }) => {
  const { t } = useTranslation('pageTitle');

  const breadcrumbs = useMemo(
    () => [
      {
        id: t('Marketing & Tracker'),
        label: t('Marketing & Tracker'),
        e2eId: `${e2ePrefix}-marketing-tracker`,
      },
      {
        id: t('Tracker Settings'),
        label: t('Tracker Settings'),
        e2eId: `${e2ePrefix}-tracker-settings`,
      },
    ],
    [e2ePrefix, t],
  );

  return breadcrumbs;
};
