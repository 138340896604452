import { some, get } from 'lodash-es';
import { useQueryInitialState } from './useInitialState';

export function useStaff(staffRole) {
  const {
    isSuccess,
    data: { staff },
  } = useQueryInitialState();
  if (!isSuccess) {
    return false;
  }
  if (Array.isArray(staffRole)) {
    return some(staffRole, (role) => get(staff, role, false));
  }
  return get(staff, staffRole, false);
}
