import { iteratee } from 'lodash-es';
import { useQueryTrackers } from 'hooks/useTrackers';

export const useTrackerInstalled = ({ kind }) => {
  const { data } = useQueryTrackers();
  if (!Array.isArray(data?.pages)) {
    return false;
  }
  return data.pages.some((page) => page.items.some(iteratee({ kind })));
};
