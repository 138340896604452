import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button, Icons } from '@shopline/dashboard-ui';
import { FormFooter as FormFooterContainer } from 'features/components';
import { withProps } from 'utils/recompose';
import PARENT_E2E_PREFIX from '../../e2ePrefix';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-form-footer`;

const OutlinePrimaryLinkButton = withProps({ as: Link })(Button.OutlinePrimary);

export const Container = FormFooterContainer;

export const CancelButton = styled(OutlinePrimaryLinkButton)`
  &:not(:first-child) {
    margin-left: 1rem;
  }
`;

export const FormFooter = ({ canSave, meta, isCreate, readonly }) => {
  const { t } = useTranslation(['tracker', 'common']);
  const { merchantId } = useParams();

  return (
    <FormFooterContainer>
      {!readonly && (
        <Button.Primary
          e2eId={`${E2E_PREFIX}-save-btn`}
          onClick={meta.handleSubmit}
          Icon={Icons.Outline.Save}
          disabled={
            !meta.touched || meta.hasErrors || meta.isSubmitting || !canSave
          }
        >
          {t('Save', { ns: 'common', context: isCreate ? '' : 'edit' })}
        </Button.Primary>
      )}
      <CancelButton
        e2eId={`${E2E_PREFIX}-cancel-btn`}
        to={{ pathname: `/${merchantId}/trackers` }}
      >
        {t('Cancel', {
          ns: 'common',
          context: readonly ? 'return' : isCreate ? '' : 'edit',
        })}
      </CancelButton>
    </FormFooterContainer>
  );
};
