import { useEffect, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { Icons } from '@shopline/dashboard-ui';
import { Loading, Popover } from 'components';
import PostMessageContext from 'contexts/PostMessage';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';
import { useQueryTracker } from 'hooks/useTrackers';
import { CRITEO, CUSTOM, GA } from 'constants/trackerKinds';
import {
  Grid,
  Page,
  PageTitle,
  Section,
  TipsContentList,
  TipsContentLinkItem,
} from '../components';
import { useBreadcrumb } from './useBreadcrumb';
import PARENT_E2E_PREFIX from './e2ePrefix';
import { useIsCreate } from './useIsCreate';
import { useIsEdit } from './useIsEdit';
import { TrackerForm } from './components';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-index`;

const IconLinkWrapper = styled.a`
  margin-left: 0.5rem;
  display: flex;
`;

const getTrackerKindBySearchParams = (search) => {
  const searchParams = new URLSearchParams(search);
  return searchParams.get('kind') ?? 'unknown';
};

export const TrackerEditor = () => {
  const { t } = useTranslation(['pageTitle', 'main', 'tracker']);
  const [, { routeChange }] = useContext(PostMessageContext);
  const isCreate = useIsCreate();
  const isEdit = useIsEdit();
  const isReadOnly = !isCreate && !isEdit;
  const { trackerId } = useParams();
  const { search } = useLocation();
  const breadcrumbs = useBreadcrumb({ e2ePrefix: E2E_PREFIX, isCreate });
  const { data: tracker, isLoading } = useQueryTracker({ trackerId });

  const props = isCreate
    ? {
        tracker: { kind: getTrackerKindBySearchParams(search) },
        readonly: isReadOnly,
      }
    : { tracker, readonly: isReadOnly };

  const pageTitleHint = useMemo(() => {
    if (!props.tracker?.kind) {
      return null;
    }
    switch (props.tracker.kind) {
      case CRITEO:
      case CUSTOM:
        return null;
      case GA:
        return (
          <Popover
            title={t('tracker:tipsPopover.title')}
            content={
              <Trans
                t={t}
                i18nKey="tracker:tipsPopover.content"
                components={{
                  list: <TipsContentList />,
                  ga: (
                    <TipsContentLinkItem
                      linkProps={{ href: t('tracker:tipsPopover.links.ga') }}
                    />
                  ),
                  gaECommerce: (
                    <TipsContentLinkItem
                      linkProps={{
                        href: t('tracker:tipsPopover.links.gaECommerce'),
                      }}
                    />
                  ),
                }}
              />
            }
            direction="BOTTOM_LEFT"
          />
        );
      default:
        return (
          <IconLinkWrapper
            href={t('docLink', {
              ns: 'main',
              context: props.tracker.kind,
            })}
            target="_blank"
          >
            <Icons.Outline.QuestionMark size="MEDIUM" color="INK_600" />
          </IconLinkWrapper>
        );
    }
  }, [props.tracker?.kind, t]);

  useScrollToTopOnMount();
  useEffect(() => {
    let path;
    if (isCreate) {
      path = '/create';
    } else if (isEdit) {
      path = `/${trackerId}/edit`;
    } else {
      path = `/${trackerId}`;
    }
    routeChange({
      path,
      search,
    });
  }, [routeChange, isCreate, isEdit, trackerId, search]);

  return (
    <Page
      titleLeftSection={
        <PageTitle hint={pageTitleHint}>
          {t('Add Event Tracker', {
            context: isCreate ? '' : 'edit',
          })}
        </PageTitle>
      }
      breadcrumbs={breadcrumbs}
    >
      <Grid.Row>
        <Grid.Column disableGutter lg={12} md={12} sd={12}>
          <Section>
            {!isCreate && isLoading ? <Loading /> : <TrackerForm {...props} />}
          </Section>
        </Grid.Column>
      </Grid.Row>
    </Page>
  );
};
