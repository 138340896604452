import styled from 'styled-components';
import { Toggle as BaseToggle } from '@shopline/dashboard-ui';
import { Text } from 'components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled(Text).attrs({
  fontType: 'Body',
  color: 'INK_800',
})`
  margin-right: 0.5rem;
`;

export const Toggle = ({ disabled, value, onClick, label, ...props }) => (
  <Container {...props}>
    <Label>{label}</Label>
    <BaseToggle isToggledOn={value} onClick={onClick} disabled={disabled} />
  </Container>
);
