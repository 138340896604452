import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { stubTrue } from 'lodash-es';
import prop from 'lodash/fp/prop';
import { FieldRow, Field, Textarea } from 'features/components';
import { CUSTOM } from 'constants/trackerKinds';
import PARENT_E2E_PREFIX from '../../e2ePrefix';
import { BaseTrackerForm } from './BaseTrackerForm';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-custom-tracker-form`;

export const additionalDefaultValuesMap = {
  isShopline: stubTrue,
};

export const additionalConfigDataMap = {
  isShopline: prop('isShopline'),
};

const useRules = () => {
  const { t } = useTranslation(['tracker', 'fieldValidation']);
  return useMemo(
    () => ({
      html: {
        required: {
          value: true,
          message: t('required', {
            ns: 'fieldValidation',
            fieldName: t('html'),
          }),
        },
        maxLength: {
          value: 100000,
          message: t('maxLength', {
            context: `${CUSTOM}Html`,
            ns: 'fieldValidation',
            fieldName: t('html'),
            value: 100000,
          }),
        },
      },
    }),
    [t],
  );
};

export const CustomTrackerForm = (props) => {
  const { t } = useTranslation('tracker');
  const rules = useRules();
  return (
    <BaseTrackerForm
      {...props}
      allowOtherEvents
      additionalDefaultValuesMap={additionalDefaultValuesMap}
      additionalConfigDataMap={additionalConfigDataMap}
      additionalRules={rules}
    >
      {({ form, meta }) => (
        <FieldRow>
          <Field title={t(`field.${CUSTOM}.html`)}>
            <Textarea
              disabled={props.readonly}
              block
              value={form.html}
              status={meta.errors.html !== '' ? 'invalid' : 'valid'}
              minHeight={16}
              maxHeight={16}
              renderMessages={[() => meta.errors.html]}
              e2eInputId={`${E2E_PREFIX}-html-input`}
              e2eMessagesId={`${E2E_PREFIX}-html-errors`}
              placeholder={t(`placeholder.${CUSTOM}.html`)}
              {...meta.handlers.html}
            />
          </Field>
        </FieldRow>
      )}
    </BaseTrackerForm>
  );
};
