import styled from 'styled-components';
import { RadioButtonWrapper } from '@shopline/dashboard-ui/dist/esm/components/choice/RadioButton';

export const RadioButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  & ${RadioButtonWrapper}:not(:first-child) {
    margin-left: 1.5rem;
  }
`;
