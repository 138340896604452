import * as trackerKinds from 'constants/trackerKinds';
import { BaseTrackerForm } from './BaseTrackerForm';
import { CustomTrackerForm } from './CustomTrackerForm';
import { Ga4TrackerForm } from './Ga4TrackerForm';
import { GAdsTrackerForm } from './GAdsTrackerForm';
import { GaTrackerForm } from './GaTrackerForm';
import { GRemarketingTrackerForm } from './GRemarketingTrackerForm';
import { LinePointsTrackerForm } from './LinePointsTrackerForm';
import { YahooTrackerForm } from './YahooTrackerForm';
import { MetaTrackerForm } from './MetaTrackerForm';

export const TrackerForm = (props) => {
  switch (props.tracker.kind) {
    case trackerKinds.GA4:
      return <Ga4TrackerForm {...props} />;
    case trackerKinds.GA:
      return <GaTrackerForm {...props} />;
    case trackerKinds.GTM:
    case trackerKinds.CRITEO:
      return <BaseTrackerForm {...props} />;
    case trackerKinds.G_ADS:
      return <GAdsTrackerForm {...props} />;
    case trackerKinds.G_REMARKETING:
      return <GRemarketingTrackerForm {...props} />;
    case trackerKinds.YAHOO:
      return <YahooTrackerForm {...props} />;
    case trackerKinds.BING:
    case trackerKinds.LINE:
      return <BaseTrackerForm {...props} allowOtherEvents />;
    case trackerKinds.META:
      return <MetaTrackerForm {...props} />;
    case trackerKinds.CUSTOM:
      return <CustomTrackerForm {...props} />;
    case trackerKinds.LINE_POINTS:
      return <LinePointsTrackerForm {...props} />;
    default:
      return null;
  }
};
